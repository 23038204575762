import { CanAccess, useOne, useShow } from "@pankod/refine-core";
import { Show, Stack, Typography, TagField, BooleanField, Tooltip, Link } from "@pankod/refine-mui";

import { Customer, User } from "interfaces";

export const UserShow: React.FC = () => {
  const { queryResult } = useShow<User>();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: customerData } = useOne<Customer>({
    resource: "customers",
    id: record?.customer.id || "",
    queryOptions: {
      enabled: !!record?.customer.id,
    },
  });

  return (
    <Show
      breadcrumb={
        null /* <Breadcrumb breadcrumbProps={{ separator: "/" }} /> */
      }
      headerProps={{
        title: <Typography variant="h5">Detalles de usuario</Typography>,
      }}
      isLoading={isLoading}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Nombre
        </Typography>
        <Typography>{record?.firstName}</Typography>
        <Typography marginTop={2} variant="body1" fontWeight="bold">
          Apellidos
        </Typography>
        <Typography>{record?.lastName}</Typography>
        <Typography marginTop={2} variant="body1" fontWeight="bold">
          Email
        </Typography>
        <span>
          <Tooltip title="Enviar email a esta dirección" arrow>
            <Link target="_blank" href={`mailto:${record?.email}`} rel="noopener noreferrer nofollow">
              <TagField style={{ cursor: 'pointer' }} value={record?.email + ' ✉️'} />
            </Link>
          </Tooltip>
        </span>
        <Typography marginTop={2} variant="body1" fontWeight="bold">
          Estado
        </Typography>
        <Stack direction={"row"}>
          <BooleanField
            value={!!record?.enabled}
            valueLabelTrue=""
            valueLabelFalse=""
          />
          {!!record?.enabled ? "Activo" : "Inactivo"}
        </Stack>
        <CanAccess
          key={"userCustomerShowField"}
          resource={"users"}
          action="field"
        >
          <Typography marginTop={2} variant="body1" fontWeight="bold">
            Cliente
          </Typography>
          <Typography>{customerData?.data.name}</Typography>
        </CanAccess>
      </Stack>
    </Show>
  );
};
