import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { HttpError, useNavigation, useOne } from "@pankod/refine-core";
import {
  Box,
  Edit,
  Typography,
  IconButton,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import SquareZoneCanvas from "components/square-zone-canvas";

import { SensorZone, Sensor } from "interfaces";
import { useEffect, useState } from "react";

export const SensorZonesEdit: React.FC = () => {
  const {
    refineCore: { formLoading, queryResult, id, onFinish },
    saveButtonProps,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<SensorZone[], HttpError, SensorZone & {
    sensorZones: {
      id?: string;
      sensorId: string;
      coords: { x: number; y: number }[];
      name?: string;
      enabled?: boolean;
      currentUsage?: number;
      maxCapacity?: number;
      createdAt?: Date;
      updatedAt?: Date;
      userId?: string;
    }[],
    width: number;
    height: number;
  }>({
    refineCoreProps: {
      liveMode: "off",
      redirect: false,
      onMutationSuccess: () => {
        // TODO: Fix "redirect without timeout does not update data in sensors/show"
        setTimeout(function () { push("/sensors/show/" + id) }, 500);
      }
    },
  });

  const { data: sensor } = useOne<Sensor>({
    resource: "sensors",
    id: id ? id : "",
    queryOptions: { enabled: id !== undefined }
  });

  const [sensorZoneData, setSensorZoneData] = useState<{
    id?: string;
    sensorId: string;
    coords: { x: number; y: number }[];
    name?: string;
    enabled?: boolean;
    currentUsage?: number;
    maxCapacity?: number;
    createdAt?: Date;
    updatedAt?: Date;
    userId?: string;
  }[]>([]);
  const [sensorZonesUpdated, setSensorZonesUpdated] = useState<boolean>(false);

  // var sensorZoneData: { id?: string; sensorId: string; coords: { x: number; y: number; }[]; name?: string; enabled?: boolean; occupiedStatus?: boolean; createdAt?: Date; updatedAt?: Date; userId?: string; }[] = [];

  useEffect(() => {

    //console.log("queryResult", queryResult)
    if (!formLoading && !sensorZonesUpdated) {
      // {coords: {x, y}[], nombre, active......}[]

      setSensorZonesUpdated(previousSensorZonesUpdated => true);
      queryResult?.data?.data.forEach(e => {
        var item = {
          id: e.id,
          sensorId: e.sensorId,
          coords: [{ x: e.x1, y: e.y1 }, { x: e.x2, y: e.y2 }, { x: e.x3, y: e.y3 }, { x: e.x4, y: e.y4 }],
          name: e.name,
          enabled: e.enabled,
          currentUsage: e.currentUsage,
          maxCapacity: e.maxCapacity,
          createdAt: e.createdAt,
          updatedAt: e.updatedAt,
          userId: e.userId
        };
        setSensorZoneData(previousSensorZoneData => [...previousSensorZoneData, item]);
      });

    }
  });

  function setFieldValue() {
    setValue("sensorZones", [...sensorZoneData]);
  }

  const { push } = useNavigation();
  const onSubmit = data => {
    onFinish(data).then((response) => {
    })
  }

  return (
    <Edit
      goBack={
        <IconButton onClick={() => push("/sensors/show/" + id)}>
          <ArrowBackIcon />
        </IconButton>
      }
      breadcrumb={null}
      headerProps={{
        title: (
          <Box>
            <Typography variant="h5">Editar zonas de detección del sensor</Typography>
            <Typography>{sensor ? sensor.data.address : ""}</Typography>
          </Box>
        ),
      }}
      headerButtons={
        <></>
      }
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        children: "Guardar",
        onClick: handleSubmit(onSubmit)
        /* successNotification: {type: "success", message: "El usuario se ha actualizado correctamente."} */
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <input type="hidden" {...register("sensorZones", { setValueAs: () => [...sensorZoneData] })} name="sensorZones" />
        <input type="hidden" {...register("sensorId", { setValueAs: () => id })} name="sensorId" />
        <input type="hidden" {...register("width", { setValueAs: () => sensor?.data.width })} name="width" />
        <input type="hidden" {...register("height", { setValueAs: () => sensor?.data.height })} name="height" />
        <Typography marginTop={2} marginBottom={1} variant="h6">
          💡 Haz click en las 4 esquinas de cada zona de detección para crear áreas a monitorizar:
        </Typography>
        <SquareZoneCanvas
          width="1200" // TODO: Do this globally configurable (WARNING: Conversion rate for sensors)
          height="675" // TODO: Do this globally configurable (WARNING: Conversion rate for sensors)
          /* imageUrl="https://picsum.photos/640/240" */
          imageUrl="http://as1.ftcdn.net/v2/jpg/04/38/25/60/1000_F_438256015_9NLvODSixwUWjawylnwHkuxB1ces1JD0.jpg"
          zones={!formLoading ? sensorZoneData : []}
          callbackUpdate={setSensorZoneData}
          setSensorZones={setFieldValue}
          sensorId={id}
          editing={true}
        />
      </Box>
    </Edit>
  );
};
