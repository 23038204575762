import React, { useEffect } from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  ShowButton,
  EditButton,
  DeleteButton,
  TagField,
  DateField,
  List,
  Stack,
  BooleanField,
  CreateButton,
  esES,
  Tooltip,
  IconButton,
  Box,
  Link,
  Button,
  Paper,
  Typography,
} from "@pankod/refine-mui";

import { Sensor, Customer, SensorType, SensorZone } from "interfaces";
import { CanAccess, useCan, useMany, useModal, usePermissions, useShow } from "@pankod/refine-core";
import { AddCircleOutline, DeveloperBoard, Inbox, MoveToInbox, RemoveCircleOutline } from "@mui/icons-material";
import { SensorZonesList } from "pages/sensor-zones/modal-show";

import { io, Socket } from "socket.io-client";
import { hasAnyRole } from "accessControlProvider";
import { dataProviderURL } from "configProvider";

const socket: Socket = io(dataProviderURL);

export const SensorList: React.FC = () => {
  const { show, visible, close } = useModal();
  const { queryResult, setShowId } = useShow<Sensor>();
  const { data: showQueryResult } = queryResult;
  const record = showQueryResult?.data;

  const { dataGridProps } = useDataGrid<Sensor>({
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc",
      },
    ],
  });

  const customerIds = [...new Set(dataGridProps.rows.map((item) => item.customerId))];
  const sensorIds = [...new Set(dataGridProps.rows.map((item) => item.id))];
  
  const { data: customersData, isLoading: customersDataIsLoading } = useMany<Customer>({
      resource: "customers",
      ids: customerIds,
      queryOptions: {
          enabled: customerIds.length > 0,
      },
  });

  const { data: sensorZonesData, isLoading: sensorZonesDataIsLoading } = useMany<SensorZone>({
    resource: "sensors/zones/filtered",
    ids: sensorIds,
    queryOptions: {
        enabled: sensorIds.length > 0,
    },
  });

  // TODO: FIX THIS, SHOULD BE USING REFINE/REACT/SOCKET THINGS
  useEffect(() => 
    {
      socket.on("sensor-zones-updated", (args) => {
        try {
          document.getElementById("occupied-" + args.id)!.innerHTML = args.occupied;
          document.getElementById("available-" + args.id)!.innerHTML = args.available;
          document.getElementById("occupied-percent-" + args.id)!.innerHTML = ((args.available/args.total*100).toFixed());
          document.getElementById("total-" + args.id)!.innerHTML = args.total;
          document.getElementById("tooltip-" + args.id)!.children[0].textContent = ((args.available/args.total*100).toFixed());
        } catch (e) {}
      } )
    }
  );


  //console.log("sensorZonesDatasensorZonesDatasensorZonesData", sensorZonesData)
  //console.log('useCan({resource: "sensors", action: "field"}).data?.can', useCan({resource: "sensors", action: "field"}).data?.can)
  const columns: GridColumns<Sensor> = [
    { field: "address",
      headerName: "Dirección/Descripción",
      flex: 1,
      minWidth: 400,
      renderCell: (params: any) => (
        <Tooltip title={params.row.address} arrow>
          <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.address}</span>
        </Tooltip>
      ),
    },
    {
      field: "createdAt",
      headerName: "Fecha Alta",
      minWidth: 160,
      flex: 1,
      renderCell: function render(params) {
        return <DateField value={params.row.createdAt} format="DD/MM/YYYY HH:mm:ss" />;
      },
    },
    {
      field: "updatedAt",
      headerName: "Última Actualización",
      minWidth: 200,
      flex: 1,
      renderCell: function render(params) {
        return <DateField value={params.row.updatedAt} format="DD/MM/YYYY HH:mm:ss" />;
      },
    },
    ...useCan({resource: "sensors", action: "field"}).data?.can ? [
      {
        field: "customerId",
        headerName: "Cliente",
        minWidth: 120,
        flex: 1,
        renderCell: function render({ row }) {
            if (customersDataIsLoading) {
                return "Cargando...";
            }
  
            const customer = customersData?.data.find(
                (item) => item.id === row.customerId,
            );
            return (
              <Tooltip title={customer?.name} arrow>
                <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{customer?.name}</span>
              </Tooltip>
            );
        },
      },
    ] : [],
    {
      field: "",
      headerName: "Detección",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: function render({ row }) {
        if (customersDataIsLoading) {
          return "Cargando...";
        }

        const customer = customersData?.data.find(
          (item) => item.id === row.customerId,
        )

        const currentUsage = sensorZonesData?.data.filter(szd => szd.sensorId === row.id && szd.enabled).reduce((accumulator, currentObj) => accumulator + currentObj.currentUsage, 0) || 0;

        const maxCapacity = sensorZonesData?.data.filter(szd => szd.sensorId === row.id && szd.enabled).reduce((accumulator, currentObj) => accumulator + currentObj.maxCapacity, 0) || 0;

        const availableCount = maxCapacity - currentUsage;

        return (
          <Tooltip id={'tooltip-' + row.id} title={`Disponibilidad: ${(availableCount && maxCapacity ? availableCount/maxCapacity*100 : 0).toFixed()} %`} arrow>
            <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              <Stack alignItems="center" justifyContent="center" direction={"row"}>
                <MoveToInbox fontSize="small" color="error" />&nbsp;<span id={'available-' + row.id}><span id={'occupied-' + row.id}>{currentUsage}</span> / {maxCapacity}</span>&nbsp;<Inbox fontSize="small" color="primary" />
              </Stack>
              <Stack alignItems="center" justifyContent="center" direction={"row"}>
                <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>Disp: <span id={'total-' + row.id}>{availableCount}</span> (<span id={'occupied-percent-' + row.id}>{(availableCount && maxCapacity ? availableCount/maxCapacity*100 : 0).toFixed()}</span>%)</Typography>
              </Stack>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "enabled",
      headerName: "Estado",
      minWidth: 20,
      flex: 1,
      align: "center",
      renderCell: function render(params) {
        return <BooleanField
          value={!!params.row.enabled}
          valueLabelTrue="Activo"
          valueLabelFalse="Inactivo"
          arrow
        />;
      },
    },
    {
      headerName: "Acciones",
      field: "actions",
      sortable: false,
      filterable: false,
      minWidth: 180,
      flex: 1,
      renderCell: function render(params) {
        return (
          <Stack direction="row" /* spacing={1} */>
            <Tooltip title="Ver detalles del sensor" arrow>
              <span>
                <ShowButton
                  hideText
                  recordItemId={params.row.id}
                />
              </span>
            </Tooltip>
            <Tooltip title="Ver zonas de detección del sensor" arrow>
              <Button
                onClick={() => {
                  show();
                  setShowId(params.row.id);
                }}
                sx={{ minWidth: 0 }}
              >
                <DeveloperBoard fontSize="small" />
              </Button>
            </Tooltip>
            <CanAccess resource="sensors" action="edit">
              <Tooltip title="Editar sensor" arrow>
                <span>
                  <EditButton
                    hideText
                    recordItemId={params.row.id}
                    color="info"
                  />
                </span>
              </Tooltip>
              <Tooltip title="Eliminar sensor" arrow>
                <span>
                  <DeleteButton
                    children={"Eliminar sensor"}
                    successNotification={{type: "success", message: "El sensor se ha eliminado correctamente."}}
                    errorNotification={{type: "error", message: "Se ha producido un error al eliminar el sensor."}}
                    confirmTitle="¿Seguro que quieres eliminar este sensor?"
                    confirmOkText="Confirmar"
                    confirmCancelText="Volver"
                    hideText
                    recordItemId={params.row.id}
                  />
                </span>
              </Tooltip>
            </CanAccess>
          </Stack>
        );
      },
    },
  ];

  const { data: permissionsData } = usePermissions();
  
  return (
    <Paper>
      <List canCreate={hasAnyRole(["SuperAdmin", "Administrador Multinivel", "Administrador"], permissionsData)} title="Sensores" headerProps={ hasAnyRole(["SuperAdmin", "Administrador Multinivel", "Administrador"], permissionsData) ? { action: <CreateButton children="Añadir Sensor" /> } : {}}>
        <DataGrid
          {...dataGridProps}
          localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: "No hay sensores que mostrar.",
            filterOperatorEquals: "es igual a",
            filterOperatorIsAnyOf: "es cualquiera de"
          }}
          columns={columns}
          autoHeight
        />
      </List>
      {record && (
        <SensorZonesList
          record={record}
          close={close}
          visible={visible}
        />
      )}
    </Paper>
  );
};
