import { useOne, useShow } from "@pankod/refine-core";
import { Show, Stack, Typography, TagField, BooleanField, Tooltip, Link } from "@pankod/refine-mui";

import { Customer, Role } from "interfaces";

export const RoleShow: React.FC = () => {
  const { queryResult } = useShow<Role>();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      breadcrumb={
        null /* <Breadcrumb breadcrumbProps={{ separator: "/" }} /> */
      }
      headerProps={{
        title: <Typography variant="h5">Detalles de rol</Typography>,
      }}
      isLoading={isLoading}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Rol
        </Typography>
        <Typography>{record?.roleName}</Typography>
        <Typography marginTop={2} variant="body1" fontWeight="bold">
          Acceso
        </Typography>
        <Typography>{record?.access}</Typography>
        <Typography marginTop={2} variant="body1" fontWeight="bold">
          Estado
        </Typography>
        <Stack direction={"row"}>
          <BooleanField
            value={!!record?.enabled}
            valueLabelTrue=""
            valueLabelFalse=""
          />
          {!!record?.enabled ? "Activo" : "Inactivo"}
        </Stack>
      </Stack>
    </Show>
  );
};
