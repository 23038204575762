import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

interface MapProps extends Exclude<google.maps.MapOptions, "center"> {
  center?: google.maps.LatLngLiteral;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (e: google.maps.Map) => void;
  mapMarkerPosition?: google.maps.LatLng;
}

const MapComponent: FC<PropsWithChildren<MapProps>> = ({
  children,
  onClick,
  onIdle,
  center,
  zoom = 12,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (map) {
      map.setOptions({ ...options, zoom, center });
    }
  }, [map, options, zoom, center]);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={{ flexGrow: "1", height: "100%" }} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // eslint-disable-next-line
          return cloneElement<any>(child, { map });
        }
      })}
    </>
  );
};

const MarkerComponent: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

const MapWrapper: FC<PropsWithChildren<MapProps>> = (props) => {
  return (
    <Wrapper apiKey={"AIzaSyAmWywrc2sYvPIka9Go09pD92iYQ0LnmUQ"} region={'ES'} language={'es'} libraries={['places']}>
      <MapComponent {...props}>
        <MarkerComponent position={props.mapMarkerPosition} />
      </MapComponent>
    </Wrapper>
  );
};

export default MapWrapper;
