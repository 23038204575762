import { ArrowBack, Edit, LinkedCamera, OpenInNew } from "@mui/icons-material";
import { CanAccess, useNavigation, useOne, useShow } from "@pankod/refine-core";
import { Show, Stack, Typography, TagField, BooleanField, Box, Button, IconButton, Link, Tooltip } from "@pankod/refine-mui";
import { axiosInstance } from "App";

import { Map } from "components/map";
import SquareZoneCanvas from "components/square-zone-canvas";
import { dataProviderURL } from "configProvider";
import { Sensor, SensorZone } from "interfaces";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

import { io, Socket } from "socket.io-client";

const socket: Socket = io(dataProviderURL);

export const SensorShow: React.FC = () => {
  const { queryResult: sensorQueryResult, showId, setShowId } = useShow<Sensor>();

  const [sensorZoneData, setSensorZoneData] = useState<{
    id?: string;
    sensorId: string;
    coords: { x: number; y: number }[];
    name?: string;
    enabled?: boolean;
    currentUsage?: number;
    maxCapacity?: number;
    createdAt?: Date;
    updatedAt?: Date;
    userId?: string;
  }[]>([]);
  const [sensorZonesUpdated, setSensorZonesUpdated] = useState<boolean>(false);

  const [sensorDetectionData, setSensorDetectionData] = useState<{
    id?: string;
    sensorZoneId: string;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    createdAt?: Date;
  }[]>([]);
  const [sensorDetectionsUpdated, setSensorDetectionsUpdated] = useState<boolean>(false);

  const { data: sensorData, isLoading: sensorIsLoading } = sensorQueryResult;
  const record = sensorData?.data;

  const sensorZonesQueryResult: any = useOne<{
    sensorZones: {
      id?: string;
      sensorId: string;
      coords: { x: number; y: number }[];
      name?: string;
      enabled?: boolean;
      currentUsage?: number;
      maxCapacity?: number;
      createdAt?: Date;
      updatedAt?: Date;
      userId?: string;
    }[]
  }>({
    resource: "sensors/zones",
    liveMode: "manual",
    onLiveEvent: async (event) => {
      let result: any = await szRefetch();
      setSensorZonesUpdated(previousSensorZonesUpdated => true);
      setSensorZoneData(previousSensorZoneData => []);
      result?.data?.data?.forEach(e => {
        var item = {
          id: e.id,
          sensorId: e.sensorId,
          coords: [{ x: e.x1, y: e.y1 }, { x: e.x2, y: e.y2 }, { x: e.x3, y: e.y3 }, { x: e.x4, y: e.y4 }],
          name: e.name,
          enabled: e.enabled,
          currentUsage: e.currentUsage,
          maxCapacity: e.maxCapacity,
          createdAt: e.createdAt,
          updatedAt: e.updatedAt,
          userId: e.userId
        };
        setSensorZoneData(previousSensorZoneData => [...previousSensorZoneData, item]);
      });

    },
    liveParams: { ids: [(showId ? showId : "")] },
    id: showId!,
    queryOptions: {
      enabled: showId !== undefined,
    },

  });

  const sensorDetectionsQueryResult: any = useOne<{
    sensorDetection: {
      id?: string;
      sensorZoneId: string;
      x1: number;
      y1: number;
      x2: number;
      y2: number;
      createdAt?: Date;
    }[]
  }>({
    resource: "sensors/detections",
    liveMode: "manual",
    onLiveEvent: async (event) => {
      let result: any = await sdRefetch();
      setSensorDetectionsUpdated(previousSensorDetectionsUpdated => true);
      setSensorDetectionData(previousSensorDetectionsData => []);

      result?.data?.data?.forEach(e => {
        setSensorDetectionData(previousSensorDetectionsData => [...previousSensorDetectionsData, e]);
      });

    },
    liveParams: { ids: [(showId ? showId : "")] },
    id: showId!,
    queryOptions: {
      enabled: showId !== undefined,
    },

  });

  const { data: sensorZonesData, isLoading: sensorZonesIsLoading, isFetched: szIsFetched, refetch: szRefetch } = sensorZonesQueryResult;
  const { data: sensorDetectionsData, isLoading: sensorDetectionsIsLoading, isFetched: sdIsFetched, refetch: sdRefetch } = sensorDetectionsQueryResult;
  const [zonesEmmited, setZonesEmmited] = useState(false);
  const [detectionsEmmited, setDetectionsEmmited] = useState(false);

  useEffect(() => {

    socket.on("sensor-zones-updated", (args) => {
      try {
        if (!zonesEmmited) {
          socket.emit("resources/sensors/zones", { data: { id: showId, resource: "sensors/zones" } })
          setZonesEmmited(false);
        }
      } catch (e) { console.log(e) }
    })

    socket.on("sensor-detections-updated", (args) => {
      try {
        if (!detectionsEmmited) {
          socket.emit("resources/sensors/detections", { data: { id: showId, resource: "sensors/detections" } })
          setDetectionsEmmited(false);
        }
      } catch (e) { console.log(e) }
    })

    //console.log("sensorZonesIsLoading", sensorZonesIsLoading)
    //console.log("sensorZonesUpdated", sensorZonesUpdated)
    if (!sensorZonesIsLoading && !sensorZonesUpdated) {
      if (!zonesEmmited) {
        socket.emit("resources/sensors/zones", { data: { id: showId, resource: "sensors/zones" } })
        socket.emit("resources/sensors/detections", { data: { id: showId, resource: "sensors/detections" } })
        setZonesEmmited(false);
      }
      //console.log("hola2")
      //console.log("sensorZonesIsLoading2", sensorZonesIsLoading)
      //console.log("sensorZonesUpdated2", sensorZonesUpdated)
      //console.log("sensorZonesData",sensorZonesData)
      setSensorZonesUpdated(previousSensorZonesUpdated => true);
      setSensorZoneData(previousSensorZoneData => []);
      sensorZonesData?.data?.forEach(e => {
        var item = {
          id: e.id,
          sensorId: e.sensorId,
          coords: [{ x: e.x1, y: e.y1 }, { x: e.x2, y: e.y2 }, { x: e.x3, y: e.y3 }, { x: e.x4, y: e.y4 }],
          name: e.name,
          enabled: e.enabled,
          currentUsage: e.currentUsage,
          maxCapacity: e.maxCapacity,
          createdAt: e.createdAt,
          updatedAt: e.updatedAt,
          userId: e.userId
        };
        setSensorZoneData(previousSensorZoneData => [...previousSensorZoneData, item]);
      });

    }
  });


  const defaultProps = {
    center: {
      lat: parseFloat(sensorQueryResult.data?.data.lat!),
      lng: parseFloat(sensorQueryResult.data?.data.lng!)
    },
    position: {
      lat: parseFloat(sensorQueryResult.data?.data.lat!),
      lng: parseFloat(sensorQueryResult.data?.data.lng!)
    },
    zoom: 1,
  };

  const setMapCenterAndMarker = (address: string) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, function (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) {
      if (status === 'OK' && results) {
        setMapZoom(18);
        setMapCenter({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  const [mapZoom, setMapZoom] = useState(6);
  const [mapMarkerPosition, setMapMarkerPosition] = useState<google.maps.LatLng>();
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
    lat: 40.2085,
    lng: -3.713
  });
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [sensorImageState, setIsSensorImageLoading] = useState({
    isLoading: false,
    buttonText: 'Actualizar imagen del sensor'
  });

  useEffect(() => {
    if (!isMapLoaded && !sensorIsLoading /* && google !== undefined */) {
      if (sensorQueryResult.data) {
        try {
          setIsMapLoaded(true);
          setMapCenter({ lat: parseFloat(sensorQueryResult.data.data.lat), lng: parseFloat(sensorQueryResult.data.data.lng) });
          setMapZoom(18);
          setMapMarkerPosition(new google.maps.LatLng(parseFloat(sensorQueryResult.data.data.lat), parseFloat(sensorQueryResult.data.data.lng)));
        } catch (except) {
          //console.log("Google error", except)
        }
      }
    }
  });

  const onMapIdle = (m: google.maps.Map) => {
    /* if(!isMapLoaded && sensorQueryResult.data){
      setIsMapLoaded(true)
      setMapCenter({lat: parseFloat(sensorQueryResult.data.data.lat), lng: parseFloat(sensorQueryResult.data.data.lng)});
      setMapZoom(18);
      setMapMarkerPosition(new google.maps.LatLng(parseFloat(sensorQueryResult.data.data.lat), parseFloat(sensorQueryResult.data.data.lng)));
    } */
  }

  const { push } = useNavigation();

  return (
    <Show
      goBack={
        <IconButton onClick={() => push("/sensors")}>
          <ArrowBack />
        </IconButton>
      }
      breadcrumb={
        null /* <Breadcrumb breadcrumbProps={{ separator: "/" }} /> */
      }
      headerProps={{
        title: <Typography variant="h5">Detalles del sensor</Typography>,
      }}
      isLoading={sensorIsLoading}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Dirección/Identificador
        </Typography>
        {record?.address}
        <Typography marginTop={2} variant="body1" fontWeight="bold">
          Coordenadas
        </Typography>
        <span>
          <Tooltip title="Abrir en una nueva ventana de Google Maps" arrow>
            <Link target="_blank" href={`https://www.google.com/maps/place/${record?.lat + ',' + record?.lng}`} rel="noopener noreferrer nofollow">
              <TagField style={{ cursor: 'pointer' }} value={record?.lat + ', ' + record?.lng + ' 🔗'} />
            </Link>
          </Tooltip>
        </span>
        <Box marginTop={2} sx={{ height: "576px", width: "100%", position: "relative" }}>
          <Map
            {...defaultProps}
            clickableIcons={false}
            streetViewControl={false}
            fullscreenControl={false}
            onIdle={onMapIdle}
            center={mapCenter}
            zoom={mapZoom}
            mapMarkerPosition={mapMarkerPosition}
          />
        </Box>

        <Typography variant="body1" fontWeight="bold">
          Tipo de sensor
        </Typography>
        {record?.sensorType.sensorType}
        <Typography marginTop={2} variant="body1" fontWeight="bold">
          Estado del sensor
        </Typography>
        <Stack direction={"row"}>
          <BooleanField
            value={!!record?.enabled}
            valueLabelTrue=""
            valueLabelFalse=""
          />
          {!!record?.enabled ? "Activo" : "Inactivo"}
        </Stack>
        <CanAccess
          key={"sensorsCustomerShowField"}
          resource={"sensors"}
          action="field"
        >
          <Typography marginTop={2} variant="body1" fontWeight="bold">
            Cliente
          </Typography>
          {record?.customer.name}
        </CanAccess>

        <Typography marginTop={5} variant="h5">Zonas de detección del sensor</Typography>
        <Typography marginBottom={3}>{record?.address}</Typography>

        <SquareZoneCanvas
          width="1200" // TODO: Do this globally configurable (WARNING: Conversion rate for sensors)
          height="675" // TODO: Do this globally configurable (WARNING: Conversion rate for sensors)
          imageUrl="https://as1.ftcdn.net/v2/jpg/04/38/25/60/1000_F_438256015_9NLvODSixwUWjawylnwHkuxB1ces1JD0.jpg"
          zones={!sensorZonesIsLoading ? sensorZoneData : []}
          detections={!sensorDetectionsIsLoading ? sensorDetectionData : []}
          sensorId={record?.id}
          readOnly={true}
        />

        <Box marginTop={3}>
          <CanAccess resource="sensors/zones" action="edit">
            <Button sx={{ marginRight: '8px' }} onClick={() => push("/sensors/zones/edit/" + record?.id)} color="info" startIcon={<Edit />} variant="contained">
              Editar zonas de detección
            </Button>
          </CanAccess>
          <Button
            disabled={sensorIsLoading || sensorImageState.isLoading}
            onClick={
              () => {
                setIsSensorImageLoading({ isLoading: true, buttonText: 'Solicitando nueva imagen...' });
                axiosInstance.patch(`${dataProviderURL}/sensors/${record?.id}/updateImage`).then(() => {
                  toast("Se ha solicitado una nueva imagen. En unos instantes estara disponible", {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    type: "success",
                    style: { width: `${"Se ha solicitado una nueva imagen. En unos instantes estara disponible".length * 10}px`, right: "20px", float: "right" }
                  });
                }).catch(() => {
                  toast("No se ha podido solicitar una nueva imagen", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    type: "error",
                    style: { width: `${"No se ha podido solicitar una nueva imagen".length * 10}px`, right: "20px", float: "right" }
                  });
                }).finally(() => {
                  setIsSensorImageLoading({ isLoading: false, buttonText: 'Actualizar imagen del sensor' });
                });
              }
            }
            color="success"
            startIcon={<LinkedCamera />}
            variant="contained">
            {sensorImageState.buttonText}
          </Button>
          <Button style={{ float: "right" }} onClick={() => push("/sensors")} startIcon={<LinkedCamera />} variant="contained">
            Volver al listado de sensores
          </Button>
          <ToastContainer />
        </Box>
      </Stack>
    </Show>
  );
};
