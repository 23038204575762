import { LiveProvider, LiveEvent } from "@pankod/refine-core";
import { dataProviderURL } from "configProvider";
import { io, Socket } from "socket.io-client";

const socket: Socket = io(dataProviderURL);

const unsubs: string[] = [];
const subs: string[] = [];

export const liveProvider = (): LiveProvider => {
  return {
    subscribe: ({ channel, types, params, callback }) => {
      //console.log("channel", channel);
      //console.log("types", types);
      //console.log("params", params);
      //console.log("callback", callback);

      //console.log("socket.connected", socket.connected);

      //console.log("params", params);

      const listener = function (message: any) {
        //console.log("return del emit", message);
        callback(message as LiveEvent);
      };

      if (!subs.includes(channel)) {
        subs.push(channel);
      }

      if (unsubs.indexOf(channel) !== -1) {
        unsubs.splice(unsubs.indexOf(channel), 1);
      }

      const channelInstance = socket.on(channel, (message) => {
        //console.log("channel", channel);
        //console.log("message", message);
        //console.log("types", types);
        //console.log("callback", callback);
        //console.log("params", params);

        if ((types.includes("*") || types.includes(message.data.type)) && !unsubs.includes(channel)) {
          if (
            message.data.type !== "created" &&
            params?.ids !== undefined &&
            message.data?.payload?.ids !== undefined
          ) {
            if (
              params.ids.filter((value) =>
                message.data.payload.ids!.includes(value)
              ).length > 0
            ) {
              callback(message.data as LiveEvent);
            }
          } else {
            callback(message.data);
          }
        }
      });

      return { channel };
    },
    unsubscribe: (payload: any) => {
      let channel: string = payload?.channel ? payload.channel : payload;
      if (!unsubs.includes(channel)) {
        unsubs.push(channel);
      }

      if (subs.indexOf(channel) !== -1) {
        subs.splice(subs.indexOf(channel), 1);
      }

    },
  };
};
