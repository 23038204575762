import { HttpError } from "@pankod/refine-core";
import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  Create,
  Typography,
} from "@pankod/refine-mui";
import { useForm, Controller } from "@pankod/refine-react-hook-form";

import { Customer } from "interfaces";

export const CustomerCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm<Customer, HttpError, Customer>();

  const { autocompleteProps } = useAutocomplete<Customer>({
    resource: "customers",
  });

  return (
    <Create
      breadcrumb={
        null /* <Breadcrumb breadcrumbProps={{ separator: "/" }} /> */
      }
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, children: "Guardar" }}
      headerProps={{
        title: <Typography variant="h5">Añadir nuevo cliente</Typography>,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("name", { required: "Este campo es obligatorio" })}
          error={!!errors?.name}
          helperText={errors.name?.message}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nombre/Denominación Cliente"
          name="name"
          autoFocus
        />
      </Box>
    </Create>
  );
};
