import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  ShowButton,
  EditButton,
  DeleteButton,
  TagField,
  DateField,
  List,
  Stack,
  BooleanField,
  CreateButton,
  esES,
  Tooltip,
} from "@pankod/refine-mui";

import { Customer } from "interfaces";
import { CanAccess, usePermissions } from "@pankod/refine-core";
import { hasAnyRole } from "accessControlProvider";

export const CustomerList: React.FC = () => {
  const { dataGridProps } = useDataGrid<Customer>({
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc",
      },
    ],
  });

  const columns: GridColumns<Customer> = [
    {
      field: "name",
      headerName: "Nombre Cliente",
      flex: 1,
      minWidth: 500,
      renderCell: (params: any) => (
        <Tooltip title={params.row.name} arrow>
          <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "createdAt",
      headerName: "Fecha Alta",
      minWidth: 150,
      flex: 1,
      renderCell: function render(params) {
        return <DateField format="DD/MM/YYYY HH:mm:ss" value={params.row.createdAt} />;
      },
    },
    {
      field: "updatedAt",
      headerName: "Fecha Actualización",
      minWidth: 180,
      flex: 1,
      renderCell: function render(params) {
        return <DateField format="DD/MM/YYYY HH:mm:ss" value={params.row.updatedAt} />;
      },
    },
    {
      field: "enabled",
      headerName: "Estado",
      minWidth: 50,
      align: "center",
      renderCell: function render(params) {
        return <BooleanField
          value={params.row.enabled}
          valueLabelTrue="Activo"
          valueLabelFalse="Inactivo"
          arrow
        />;
      },
    },
    {
      headerName: "Acciones",
      field: "actions",
      /* type: "actions", */
      sortable: false,
      filterable: false,
      minWidth: 200,
      flex: 1,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title="Mostrar cliente" arrow>
              <span>
                <ShowButton
                  hideText
                  recordItemId={params.row.id}
                />
              </span>
            </Tooltip>
            <CanAccess resource="customers" action="edit">
              <Tooltip title="Editar cliente" arrow>
                <span>
                  <EditButton
                    color="info"
                    hideText
                    recordItemId={params.row.id}
                  />
                </span>
              </Tooltip>
              <Tooltip title="Eliminar cliente" arrow>
                <span>
                  <DeleteButton
                    children={"Eliminar cliente"}
                    successNotification={{type: "success", message: "El cliente se ha eliminado correctamente."}}
                    errorNotification={{type: "error", message: "Se ha producido un error al eliminar el cliente."}}
                    confirmTitle={"¿Seguro que quieres eliminar este cliente?"}
                    confirmOkText={"Confirmar"}
                    confirmCancelText={"Volver"}
                    hideText
                    recordItemId={params.row.id}
                    onSuccess={(value) => {
                      //console.log(value);
                    }}
                  />
                </span>
              </Tooltip>
            </CanAccess>
          </Stack>
        );
      },
    },
  ];

  const { data: permissionsData } = usePermissions();

  return (
    <List title="Clientes" canCreate={hasAnyRole(["SuperAdmin", "Administrador Multinivel"], permissionsData)} headerProps={ hasAnyRole(["SuperAdmin", "Administrador Multinivel"], permissionsData) ? { action: <CreateButton children="Añadir Cliente" /> } : {}}>
      <DataGrid {...dataGridProps}
        localeText={{
          ...esES.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: "No hay clientes que mostrar.",
          filterOperatorEquals: "es igual a",
          filterOperatorIsAnyOf: "es cualquiera de"
        }}
        columns={columns}
        autoHeight
      />
    </List>
  );
};
