import { IAccessControlContext } from "@pankod/refine-core";
import { authProvider } from "./authProvider";
import jwt_decode from "jwt-decode";

export function hasAnyRole(allowedRoles: string[], userRoles: string[]) {
  return userRoles?.some((elem) => {
    return allowedRoles.includes(elem);
  });
}

export const accessControlProvider: Required<IAccessControlContext> = {
  can: async ({ resource, action, params }) => {
    const permissionsData = await authProvider.getPermissions();
    const token = localStorage.getItem("auth") || "";
    const decodedToken: any = jwt_decode(token);
    /*
    if (resource == "userCustomerEditField") {
      return Promise.resolve({
        can: false,
        reason: "Unauthorized",
      });
    }
    */

    if (resource === "sensors") {
      switch (action) {
        case "field": 
        case "edit": 
        case "delete": 
          if (
            !hasAnyRole(
              ["Administrador Multinivel", "SuperAdmin"],
              permissionsData
            )
          ) {
            return Promise.resolve({
              can: false,
              reason: "Unauthorized",
            });
          }
          break;
      }
    }

    if (resource === "sensors/zones") {
      switch (action) {
        case "field": 
        case "edit": 
        case "delete": 
          if (
            !hasAnyRole(
              ["Administrador Multinivel", "SuperAdmin"],
              permissionsData
            )
          ) {
            return Promise.resolve({
              can: false,
              reason: "Unauthorized",
            });
          }
          break;
      }
    }

    if (resource === "users") {
      switch (action) {
        case "field":
          if (params?.field === "userRoleEditField" && decodedToken.sub === params?.id) {
            return Promise.resolve({
              can: false,
              reason: "Unauthorized",
            });
          }
          if (
            !hasAnyRole(
              ["Administrador Multinivel", "SuperAdmin"],
              permissionsData
            )
          ) {
            return Promise.resolve({
              can: false,
              reason: "Unauthorized",
            });
          }
          break;
        case "edit":
        case "delete":
          if (
            !hasAnyRole(
              ["Administrador Multinivel", "SuperAdmin"],
              permissionsData
            )
          ) {
            return Promise.resolve({
              can: false,
              reason: "Unauthorized",
            });
          }
          break;
      }
    }

    if (resource === "customers") {
      if (
        !hasAnyRole(["Administrador Multinivel", "SuperAdmin"], permissionsData)
      ) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }
    }

    if (resource === "roles") {
      if (!hasAnyRole(["SuperAdmin"], permissionsData)) {
        return Promise.resolve({
          can: false,
          reason: "Unauthorized",
        });
      }
    }

    /* if (resource === "sensors" && action === "list") {
      return Promise.resolve({
        can: false,
        reason: "Unauthorized",
      });
    } */

    // or you can access directly *resource object
    // const resourceName = params?.resource?.name;
    // const anyUsefulOption = params?.resource?.options?.yourUsefulOption;
    // if (resourceName === "posts" && anyUsefulOption === true && action === "edit") {
    //     return Promise.resolve({
    //         can: false,
    //         reason: "Unauthorized",
    //     });
    // }

    return Promise.resolve({ can: true });
  },
};
