import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CanAccess, HttpError } from "@pankod/refine-core";
import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  Create,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@pankod/refine-mui";
import { useForm, Controller } from "@pankod/refine-react-hook-form";

import { User, Customer, Role } from "interfaces";
import { useState } from "react";

export const UserCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm<User, HttpError, User & { customer: Customer }>();

  const { autocompleteProps: customerAutocompleteProps } = useAutocomplete<Customer>({
    resource: "customers",
    filters: [
      {
        field: "enabled",
        operator: "eq",
        value: true,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value,
      },
    ],
  });

  const { autocompleteProps: roleAutocompleteProps } = useAutocomplete<Role>({
    resource: "roles",
    filters: [
      {
        field: "enabled",
        operator: "eq",
        value: true,
      },
    ],
    onSearch: (value) => [
      {
        field: "roleName",
        operator: "contains",
        value,
      },
    ],
  });

  const [values, setValues] = useState({
    password: '',
    showPassword: false,
    password2: '',
    showPassword2: false,
  });

  const handleChange =
    (prop) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
      showPassword2: !values.showPassword2,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Create
      breadcrumb={
        null /* <Breadcrumb breadcrumbProps={{ separator: "/" }} /> */
      }
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, children: "Crear Usuario" }}
      headerProps={{
        title: <Typography variant="h5">Crear nuevo usuario</Typography>,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("firstName", { required: "Este campo es obligatorio" })}
          error={!!errors?.firstName}
          helperText={errors.firstName?.message}
          margin="normal"
          required
          fullWidth
          id="firstName"
          label="Nombre"
          name="firstName"
          autoFocus
        />
        <TextField
          {...register("lastName", { required: "Este campo es obligatorio" })}
          error={!!errors?.lastName}
          helperText={errors.lastName?.message}
          margin="normal"
          required
          fullWidth
          id="lastName"
          label="Apellidos"
          name="lastName"
        />
        <TextField
          {...register("email", { required: "Este campo es obligatorio", pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Introduce un email válido"
          }})}
          error={!!errors?.email}
          helperText={errors.email?.message}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
        />
        <TextField
          {...register("password", { required: "Este campo es obligatorio" })}
          error={!!errors?.password}
          helperText={errors.password?.message}
          margin="normal"
          required
          fullWidth
          id="password"
          label="Contraseña"
          name="password"
          type={values.showPassword ? 'text' : 'password'}
          onChange={handleChange('password')}
          InputProps={{endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Mostrar/ocultar contraseña"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )}}
        />
        <TextField
          {...register("password2", {
            required: "Este campo es obligatorio", validate: value =>
            value === values.password || "Las contraseñas no coinciden"
          })}
          error={!!errors?.password2}
          helperText={errors.password2?.message}
          margin="normal"
          required
          fullWidth
          id="password2"
          label="Confirmar Contraseña"
          name="password2"
          type={values.showPassword2 ? 'text' : 'password'}
          InputProps={{endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Mostrar/ocultar contraseña"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword2 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )}}
        />
        {/* <FormControl sx={{ mt: 2, mb: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
          <OutlinedInput
            {...register("password", { required: "Este campo es obligatorio" })}
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Mostrar/ocultar contraseña"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Contraseña"
          />
        </FormControl>
        <FormControl sx={{ mt: 2, mb: 1, width: '25ch' }} variant="outlined" {...register("password2", { required: "Este campo es obligatorio" })}>
          <InputLabel htmlFor="outlined-adornment-password">Confirmar Contraseña</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword2 ? 'text' : 'password'}
            value={values.password2}
            onChange={handleChange('password2')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Mostrar/ocultar contraseña"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword2 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirmar Contraseña"
          />
        </FormControl> */}
        <Controller
          control={control}
          name="role"
          rules={{ required: "Este campo es obligatorio" }}
          render={({ field }) => (
            <Autocomplete
              {...roleAutocompleteProps}
              {...field}
              value={field.value || null}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              filterOptions={(options, state) => {
                let newOptions: Role[] = [];
                options.forEach((element) => {
                  if (
                    element.roleName
                      .replace(",", "")
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  )
                  newOptions.push(element);
                });
                return newOptions;
              }}
              getOptionLabel={(item) => {
                return item.roleName ? item.roleName : "";
              }}
              isOptionEqualToValue={(option, value: any) =>
                  value === undefined || option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Rol"
                  margin="normal"
                  variant="outlined"
                  error={!!errors.role}
                  helperText={errors.role?.message}
                  required
                />
              )}
            />
          )}
        />
        <CanAccess
          key={"userCustomerCreateField"}
          resource={"users"}
          action="field"
        >
          <Controller
            control={control}
            name="customer"
            rules={{ required: "Este campo es obligatorio" }}
            render={({ field }) => (
              <Autocomplete
                {...customerAutocompleteProps}
                {...field}
                value={field.value || null}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                filterOptions={(options, state) => {
                  let newOptions: Customer[] = [];
                  options.forEach((element) => {
                    if (
                      element.name
                        .replace(",", "")
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    )
                    newOptions.push(element);
                  });
                  return newOptions;
                }}
                getOptionLabel={(item) => {
                  return item.name ? item.name : "";
                }}
                isOptionEqualToValue={(option, value: any) =>
                    value === undefined || option.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente"
                    margin="normal"
                    variant="outlined"
                    error={!!errors.customer}
                    helperText={errors.customer?.message}
                    required
                  />
                )}
              />
            )}
          />
        </CanAccess>
      </Box>
    </Create>
  );
};
