import { HttpError, useSelect } from "@pankod/refine-core";
import {
  Edit,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { dataProviderURL } from "configProvider";

import { Customer } from "interfaces";
import { useEffect, useState } from "react";

import { io, Socket } from "socket.io-client";

const socket: Socket = io(dataProviderURL);

export const CustomerEdit: React.FC = () => {
  const {
    refineCore: { onFinish, formLoading, id },
    saveButtonProps,
    register,
    handleSubmit,
    resetField,
    getValues,
    control,
    formState: { errors },
  } = useForm<Customer, HttpError, Customer>({ refineCoreProps : { liveMode: "off" } });

  const [customerEnabled, setCustomerEnabled] = useState(getValues().enabled);
  const [emmited, setEmmited] = useState(false);


  useEffect(() => {
    setCustomerEnabled(getValues().enabled)
    if(formLoading && !emmited){
      socket.emit("resources/customers", {data: {id, resource: "customers"}})
      setEmmited(false);
    }
  });


  return (
    <Edit
      breadcrumb={
        null /* <Breadcrumb breadcrumbProps={{ separator: "/" }} /> */
      }
      headerProps={{
        title: <Typography variant="h5">Editar cliente</Typography>,
      }}
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        children: "Guardar",
        /* successNotification: {type: "success", message: "El usuario se ha actualizado correctamente."} */
      }}
      deleteButtonProps={{
        children: "Eliminar cliente",
        /* errorNotification: {type: "error", message: "Se ha producido un error al eliminar el usuario."}, */
        confirmTitle: "¿Seguro que quieres eliminar este cliente?",
        confirmOkText: "Confirmar",
        confirmCancelText: "Volver"
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("name", { required: "Este campo es obligatorio" })}
          error={!!errors?.name}
          helperText={errors.name?.message}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nombre/Denominación Cliente"
          name="name"
          defaultValue={" "}
          autoFocus
        />
        <FormControlLabel
          style={{ pointerEvents: "none" }}
          control={<Checkbox
            {...register("enabled")}
            id="enabled"
            title="Activo"
            name="enabled"
            checked={ !!customerEnabled }
            onClick={() => {
              setCustomerEnabled(!customerEnabled);
            }}
            style={{ pointerEvents: "auto" }} 
          />}
          label="Activo"
        />
      </Box>
    </Edit>
  );
};
