import { HttpError } from "@pankod/refine-core";
import {
  Edit,
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { dataProviderURL } from "configProvider";

import { Customer, Role } from "interfaces";
import { useEffect, useState } from "react";

import { io, Socket } from "socket.io-client";

const socket: Socket = io(dataProviderURL);

export const RoleEdit: React.FC = () => {
  const {
    refineCore: { formLoading, queryResult, id },
    saveButtonProps,
    register,
    getValues,
    formState: { errors },
  } = useForm<Role, HttpError, Role>();

  
  const [roleEnabled, setRoleEnabled] = useState(getValues().enabled);
  const [emmited, setEmmited] = useState(false);

  useEffect(() => {
    setRoleEnabled(getValues().enabled)
    if(formLoading && !emmited){
      socket.emit("resources/roles", {data: {id, resource: "roles"}})
      setEmmited(false);
    }
  });

  return (
    <Edit
      breadcrumb={
        null /* <Breadcrumb breadcrumbProps={{ separator: "/" }} /> */
      }
      headerProps={{
        title: <Typography variant="h5">Editar rol</Typography>,
      }}
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        children: "Guardar",
        /* successNotification: {type: "success", message: "El usuario se ha actualizado correctamente."} */
      }}
      deleteButtonProps={{
        children: "Eliminar rol",
        /* errorNotification: {type: "error", message: "Se ha producido un error al eliminar el usuario."}, */
        confirmTitle: "¿Seguro que quieres eliminar este rol?",
        confirmOkText: "Confirmar",
        confirmCancelText: "Volver"
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("roleName", { required: "Este campo es obligatorio" })}
          error={!!errors?.roleName}
          helperText={errors.roleName?.message}
          margin="normal"
          required
          fullWidth
          id="roleName"
          label="Rol"
          name="roleName"
          defaultValue={" "}
        />
        <TextField
          {...register("access", { required: "Este campo es obligatorio" })}
          error={!!errors?.access}
          helperText={errors.access?.message}
          margin="normal"
          required
          fullWidth
          id="access"
          label="Acceso"
          name="access"
          defaultValue={" "}
        />
        <FormControlLabel
          style={{ pointerEvents: "none" }}
          control={<Checkbox
            {...register("enabled")}
            id="enabled"
            title="Activo"
            name="enabled"
            checked={ !!roleEnabled }
            onClick={() => {
              setRoleEnabled(!roleEnabled);
            }}
            style={{ pointerEvents: "auto" }} 
          />}
          label="Activo"
        />
      </Box>
    </Edit>
  );
};
