import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  ShowButton,
  EditButton,
  DeleteButton,
  TagField,
  DateField,
  List,
  Stack,
  BooleanField,
  CreateButton,
  esES,
  Tooltip,
} from "@pankod/refine-mui";

import { User, Customer } from "interfaces";
import { CanAccess, useCan, useMany, usePermissions } from "@pankod/refine-core";
import { Email } from "@mui/icons-material";
import { hasAnyRole } from "accessControlProvider";

export const UserList: React.FC = () => {
  const { dataGridProps } = useDataGrid<User>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  });

  //let customerIds = dataGridProps.rows.map((item) => item.customerId);
  //customerIds = customerIds.filter((item, index) => customerIds.indexOf(item) === index);
  const customerIds = [...new Set(dataGridProps.rows.map((item) => item.customerId))];

  const { data: customersData, isLoading } = useMany<Customer>({
      resource: "customers",
      ids: customerIds,
      queryOptions: {
          enabled: customerIds.length > 0,
      },
  });

  const columns: GridColumns<User> = [
    { field: "firstName", headerName: "Nombre", flex: 1, minWidth: 100, renderCell: (params: any) => (
        <Tooltip title={params.row.firstName} arrow>
          <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.firstName}</span>
        </Tooltip>
      ),
    },
    { field: "lastName", headerName: "Apellidos", flex: 1, minWidth: 150, renderCell: (params: any) => (
        <Tooltip title={params.row.lastName} arrow>
          <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.lastName}</span>
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      flex: 1,
      renderCell: function render(params) {
        return (
          <Tooltip title={params.row.email} arrow>
            <span>
              <TagField /* icon={<Email />} */ value={params.row.email} title={params.row.email} />
            </span>
          </Tooltip>
        );
      },
    },
    ...useCan({resource: "users", action: "field"}).data?.can ? [
      {
        field: "customerId",
        headerName: "Cliente",
        minWidth: 280,
        flex: 1,
        renderCell: function render({ row }) {
            if (isLoading) {
                return "Cargando...";
            }
  
            const customer = customersData?.data.find(
                (item) => item.id === row.customerId,
            );
            return (
              <Tooltip title={customer?.name} arrow>
                <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{customer?.name}</span>
              </Tooltip>
            );
        },
      },
    ] : [],
    {
      field: "createdAt",
      headerName: "Fecha Alta",
      minWidth: 150,
      flex: 1,
      renderCell: function render(params) {
        return <DateField value={params.row.createdAt} format="DD/MM/YYYY HH:mm:ss" />;
      },
    },
    {
      field: "enabled",
      headerName: "Estado",
      minWidth: 30,
      flex: 1,
      align: "center",
      renderCell: function render(params) {
        return <BooleanField
          value={params.row.enabled}
          valueLabelTrue="Activo"
          valueLabelFalse="Inactivo"
          arrow
        />;
      },
    },
    {
      headerName: "Acciones",
      field: "actions",
      sortable: false,
      filterable: false,
      minWidth: 200,
      flex: 1,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title="Mostrar usuario" arrow>
              <span>
                <ShowButton
                  hideText
                  recordItemId={params.row.id}
                />
              </span>
            </Tooltip>
            <CanAccess resource="users" action="edit">
              <Tooltip title="Editar usuario" arrow>
                <span>
                  <EditButton
                    color="info"
                    hideText
                    recordItemId={params.row.id}
                  />
                </span>
              </Tooltip>
              <Tooltip title="Eliminar usuario" arrow>
                <span>
                  <DeleteButton
                    children={"Eliminar usuario"}
                    successNotification={{type: "success", message: "El usuario se ha eliminado correctamente."}}
                    errorNotification={{type: "error", message: "Se ha producido un error al eliminar el usuario."}}
                    confirmTitle="¿Seguro que quieres eliminar este usuario?"
                    confirmOkText="Confirmar"
                    confirmCancelText="Volver"
                    hideText
                    recordItemId={params.row.id}
                  />
                </span>
              </Tooltip>
            </CanAccess>
          </Stack>
        );
      },
    },
  ];

  const { data: permissionsData } = usePermissions();
  
  return (
    <List canCreate={hasAnyRole(["SuperAdmin", "Administrador Multinivel", "Administrador"], permissionsData)} title="Usuarios" headerProps={ hasAnyRole(["SuperAdmin", "Administrador Multinivel", "Administrador"], permissionsData) ? { action: <CreateButton children="Crear Usuario" /> } : {}}>
      <DataGrid {...dataGridProps}
        localeText={{
          ...esES.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: "No hay usuarios que mostrar.",
          filterOperatorEquals: "es igual a",
          filterOperatorIsAnyOf: "es cualquiera de"
        }}
        columns={columns}
        autoHeight />
    </List>
  );
};
