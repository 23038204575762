import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  ShowButton,
  EditButton,
  DeleteButton,
  TagField,
  DateField,
  List,
  Stack,
  BooleanField,
  CreateButton,
  esES,
  Tooltip,
} from "@pankod/refine-mui";

import { Role } from "interfaces";

export const RoleList: React.FC = () => {
  const { dataGridProps } = useDataGrid<Role>({
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc",
      },
    ],
  });

  const columns: GridColumns<Role> = [
    { field: "roleName", headerName: "Rol", flex: 1, minWidth: 500, renderCell: (params: any) => (
        <Tooltip title={params.row.firstName} arrow>
          <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.roleName}</span>
        </Tooltip>
      ),
    },
    { field: "access", headerName: "Acceso", flex: 1, minWidth: 50, renderCell: (params: any) => (
        <Tooltip title={params.row.lastName} arrow>
          <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.access}</span>
        </Tooltip>
      ),
    },
    {
      field: "createdAt",
      headerName: "Fecha Alta",
      minWidth: 170,
      flex: 1,
      renderCell: function render(params) {
        return <DateField value={params.row.createdAt} format="DD/MM/YYYY HH:mm:ss" />;
      },
    },
    {
      field: "updatedAt",
      headerName: "Última Actualización",
      minWidth: 200,
      flex: 1,
      renderCell: function render(params) {
        return <DateField value={params.row.updatedAt} format="DD/MM/YYYY HH:mm:ss" />;
      },
    },
    {
      field: "enabled",
      headerName: "Estado",
      minWidth: 30,
      flex: 1,
      align: "center",
      renderCell: function render(params) {
        return <BooleanField
          value={params.row.enabled}
          valueLabelTrue="Activo"
          valueLabelFalse="Inactivo"
          arrow
        />;
      },
    },
    {
      headerName: "Acciones",
      field: "actions",
      sortable: false,
      filterable: false,
      minWidth: 200,
      flex: 1,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title="Mostrar rol" arrow>
              <span>
                <ShowButton
                  hideText
                  recordItemId={params.row.id}
                />
              </span>
            </Tooltip>
            <Tooltip title="Editar rol" arrow>
              <span>
                <EditButton
                  color="info"
                  hideText
                  recordItemId={params.row.id}
                />
              </span>
            </Tooltip>
            <Tooltip title="Eliminar rol" arrow>
              <span>
                <DeleteButton
                  children={"Eliminar usroluario"}
                  successNotification={{type: "success", message: "El rol se ha eliminado correctamente."}}
                  errorNotification={{type: "error", message: "Se ha producido un error al eliminar el rol."}}
                  confirmTitle="¿Seguro que quieres eliminar este rol?"
                  confirmOkText="Confirmar"
                  confirmCancelText="Volver"
                  hideText
                  recordItemId={params.row.id}
                />
              </span>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <List title="Roles" cardHeaderProps={{ action: <CreateButton children="Crear Rol" /> }}>
      <DataGrid {...dataGridProps}
        localeText={{
          ...esES.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: "No hay roles que mostrar.",
          filterOperatorEquals: "es igual a",
          filterOperatorIsAnyOf: "es cualquiera de"
        }}
        columns={columns}
        autoHeight />
    </List>
  );
};
