import { useOne, useShow } from "@pankod/refine-core";
import { Show, Stack, Typography, TagField, BooleanField, DateField } from "@pankod/refine-mui";

import { Customer } from "interfaces";

export const CustomerShow: React.FC = () => {
  const { queryResult } = useShow<Customer>();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      breadcrumb={
        null /* <Breadcrumb breadcrumbProps={{ separator: "/" }} /> */
      }
      headerProps={{
        title: <Typography variant="h5">Detalles de cliente</Typography>,
      }}
      isLoading={isLoading}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Nombre/Denominación del cliente
        </Typography>
        <Typography>{record?.name}</Typography>
        <Typography marginTop={2} variant="body1" fontWeight="bold">
          Fecha de alta
        </Typography>
        <DateField format="DD/MM/YYYY HH:mm:ss" value={record?.createdAt} />
        <Typography marginTop={2} variant="body1" fontWeight="bold">
          Última modificación
        </Typography>
        <DateField format="DD/MM/YYYY HH:mm:ss" value={record?.updatedAt} />
        <Typography marginTop={2} variant="body1" fontWeight="bold">
          Estado
        </Typography>
        <Stack direction={"row"}>
          <BooleanField
            value={!!record?.enabled}
            valueLabelTrue=""
            valueLabelFalse=""
          />
          {!!record?.enabled ? "Activo" : "Inactivo"}
        </Stack>
      </Stack>
    </Show>
  );
};
