import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CanAccess, HttpError } from "@pankod/refine-core";
import {
  Edit,
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  FormControlLabel,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";

import { useEffect, useState } from "react";
import { Customer, Role, User } from "interfaces";

import { io, Socket } from "socket.io-client";
import { dataProviderURL } from "configProvider";

const socket: Socket = io(dataProviderURL);

export const UserEdit: React.FC = () => {
  const {
    refineCore: { formLoading, queryResult, id },
    saveButtonProps,
    getValues,
    register,
    control,
    formState: { errors },
  } = useForm<User, HttpError, User & {customer: Customer}>({
    refineCoreProps: { metaData: { populate: ["customer"] } },
  });
//console.log("getValues", getValues())
//console.log("queryResult?.data?.data.customerId", queryResult?.data?.data)
  const { autocompleteProps: customerAutocompleteProps } = useAutocomplete<Customer>({
    resource: "customers",
    defaultValue: queryResult?.data?.data.customerId,
    queryOptions: { enabled: !!queryResult?.data?.data.customerId },
    filters: [
      {
        field: "enabled",
        operator: "eq",
        value: true,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value,
      },
    ],
  });
  
  const { autocompleteProps: roleAutocompleteProps } = useAutocomplete<Role>({
    resource: "roles",
    defaultValue: queryResult?.data?.data.roleId,
    queryOptions: { enabled: !!queryResult?.data?.data.roleId },
    filters: [
      {
        field: "enabled",
        operator: "eq",
        value: true,
      },
    ],
    onSearch: (value) => [
      {
        field: "roleName",
        operator: "contains",
        value,
      },
    ],
  });

  const [customerEnabled, setCustomerEnabled] = useState(getValues().enabled);
  const [emmited, setEmmited] = useState(false);

  useEffect(() => {
    setCustomerEnabled(getValues().enabled)
    if(formLoading && !emmited){
      socket.emit("resources/users", {data: {id, resource: "users"}})
      setEmmited(false);
    }
  });

  const [values, setValues] = useState({
    password: '',
    showPassword: false,
    password2: '',
    showPassword2: false,
  });

  const handleChange =
    (prop) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
      showPassword2: !values.showPassword2,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Edit
      breadcrumb={
        null /* <Breadcrumb breadcrumbProps={{ separator: "/" }} /> */
      }
      headerProps={{
        title: <Typography variant="h5">Editar usuario</Typography>,
      }}
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        children: "Guardar",
        /* successNotification: {type: "success", message: "El usuario se ha actualizado correctamente."} */
      }}
      deleteButtonProps={{
        children: "Eliminar usuario",
        /* errorNotification: {type: "error", message: "Se ha producido un error al eliminar el usuario."}, */
        confirmTitle: "¿Seguro que quieres eliminar este usuario?",
        confirmOkText: "Confirmar",
        confirmCancelText: "Volver"
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("firstName", { required: "Este campo es obligatorio" })}
          error={!!errors?.firstName}
          helperText={errors.firstName?.message}
          margin="normal"
          required
          fullWidth
          id="firstName"
          label="Nombre"
          name="firstName"
          defaultValue={" "}
        />
        <TextField
          {...register("lastName", { required: "Este campo es obligatorio" })}
          error={!!errors?.lastName}
          helperText={errors.lastName?.message}
          margin="normal"
          required
          fullWidth
          id="lastName"
          label="Apellidos"
          name="lastName"
          defaultValue={" "}
        />
        <TextField
          {...register("email", { required: "Este campo es obligatorio", pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Introduce un email válido"
          }})}
          error={!!errors?.email}
          helperText={errors.email?.message}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          defaultValue={" "}
        />
        {/* <Controller
          control={control}
          name="customer"
          rules={{ required: "Este campo es obligatorio" }}
          defaultValue={null as any}
          render={({ field }) => (
            <Autocomplete
              {...customerAutocompleteProps}
              {...field}
              {...register("customer")}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => {
                return item.name ? item.name : "";
              }}
              isOptionEqualToValue={(option, value) =>
                value === undefined || option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  margin="normal"
                  variant="outlined"
                  error={!!errors.customer}
                  helperText={errors.customer?.message}
                  required
                />
              )}
            />
          )}
        /> */}

        <CanAccess
          key={"userRoleEditField"}
          resource={"users"}
          action="field"
          params={{field: "userRoleEditField", id}}
        >
          <Controller
            control={control}
            name="role"
            rules={{ required: "Este campo es obligatorio" }}
            defaultValue={null as any}
            render={({ field }) => (
              <Autocomplete
                {...roleAutocompleteProps}
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                filterOptions={(options, state) => {
                  let newOptions: Role[] = [];
                  options.forEach((element) => {
                    if (
                      element.roleName
                        .replace(",", "")
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    )
                    newOptions.push(element);
                  });
                  return newOptions;
                } }
                getOptionLabel={(item) => {
                  return item.roleName
                    ? item.roleName
                    : roleAutocompleteProps?.options?.find(
                        (p) =>
                          p.id.toString() ===
                          item.id.toString(),
                      )?.roleName ?? "";
                }}
                isOptionEqualToValue={(option, value) =>
                  value === undefined ||
                  option.id.toString() === value.id.toString()
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Rol"
                    margin="normal"
                    variant="outlined"
                    error={!!errors.roleId}
                    helperText={errors.roleId?.message}
                    required
                  />
                )}
                noOptionsText="No hay resultados de roles"
                clearText="Borrar resultados"
                openText="Mostrar resultados"
                closeText="Ocultar resultados"
                loadingText="Cargando..."
              />
            )}
          />
        </CanAccess>

        <CanAccess
          key={"userCustomerEditField"}
          resource={"users"}
          action="field"
        >
          <Controller
            control={control}
            name="customer"
            rules={{ required: "Este campo es obligatorio" }}
            defaultValue={null as any}
            render={({ field }) => (
              <Autocomplete
                {...customerAutocompleteProps}
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                /* value={ customerAutocompleteProps.options.filter(customer => customer.id === getValues().customerId)[0] } */
                filterOptions={(options, state) => {
                  let newOptions: Customer[] = [];
                  options.forEach((element) => {
                    if (
                      element.name
                        .replace(",", "")
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    )
                    newOptions.push(element);
                  });
                  return newOptions;
                } }
                getOptionLabel={(item) => {
                  return item.name
                    ? item.name
                    : customerAutocompleteProps?.options?.find(
                        (p) =>
                          p.id.toString() ===
                          item.id.toString(),
                      )?.name ?? "";
                }}
                isOptionEqualToValue={(option, value) =>
                  value === undefined ||
                  option.id.toString() === value.id.toString()
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente"
                    margin="normal"
                    variant="outlined"
                    error={!!errors.customerId}
                    helperText={errors.customerId?.message}
                    required
                  />
                )}
                noOptionsText="No hay resultados de clientes"
                clearText="Borrar resultados"
                openText="Mostrar resultados"
                closeText="Ocultar resultados"
                loadingText="Cargando..."
              />
            )}
          />
        </CanAccess>

        <FormControlLabel
          style={{ pointerEvents: "none" }}
          control={<Checkbox
            {...register("enabled")}
            id="enabled"
            title="Activo"
            name="enabled"
            checked={ !!customerEnabled }
            onClick={() => {
              setCustomerEnabled(!customerEnabled);
            }}
            style={{ pointerEvents: "auto" }} 
          />}
          label="Activo"
        />
      </Box>
    </Edit>
  );
};
