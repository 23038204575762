import { Box, Button, Tooltip, Typography } from "@pankod/refine-mui";
import { useRouterContext/* , TitleProps */ } from "@pankod/refine-core";
//import logo from "../../assets/images/logo.svg";
//import logoCollapsed from "../../assets/images/logo-collapsed.svg";
import logo from "../../assets/images/pairk_logo.png";
import icon from "../../assets/images/pairk_icon.png";
import "./style.less";

type TitleProps = {
  collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { Link } = useRouterContext();

  return (
    <Button fullWidth variant="text" disableRipple href="/">
        {collapsed ? (
          <Tooltip title="Pairk - Inicio">
            {/* <img src={logoCollapsed} alt="Pairk" width="28px" /> */}
            <img src={icon} alt="Pairk" width="28px" />
            {/* <Button>
              <Typography variant="h5">P</Typography>
            </Button> */}
          </Tooltip>
        ) : (
          <Tooltip title="Pairk - Inicio">
            {/* <img src={logo} alt="Pairk" width="140px" /> */}
            <img src={logo} alt="Pairk" width="100px" style={{marginTop: 15}} />
            {/* <Button>
              <Typography variant="h5">Pairk</Typography>
            </Button> */}
          </Tooltip>
        )}
    </Button>
  );
  /* return (
    <a href="/">
      <div className="logo">
        {collapsed ? (
          <img src="/images/logo-collapsed.svg" alt="Pairk" />
        ) : (
          <img src="/images/logo.svg" alt="Pairk" />
        )}
      </div>
    </a>
  ); */
};
