import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import { ThemeProvider } from "@pankod/refine-mui";
import { DarkTheme, LightTheme } from "@pankod/refine-mui";

type ColorModeContextType = {
  mode: string;
  setMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const colorModeFromLocalStorage = localStorage.getItem("colorMode");
  const isSystemPreferenceDark = window?.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;

  const systemPreference = isSystemPreferenceDark ? "dark" : "light";
  const [mode, setMode] = useState(
    colorModeFromLocalStorage || systemPreference
  );

  useEffect(() => {
    window.localStorage.setItem("colorMode", mode);
  }, [mode]);

  const setColorMode = () => {
    if (mode === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
  };

  LightTheme.palette.primary.main = '#00bbb4'
  LightTheme.palette.primary.light = '#00bdb6'
  LightTheme.palette.primary.dark = '#00a39e'

  LightTheme.palette.secondary.main = '#003330'
  LightTheme.palette.secondary.light = '#003330'
  LightTheme.palette.secondary.dark = '#003330'

  DarkTheme.palette.primary.main = '#009b94'
  DarkTheme.palette.primary.light = '#009d96'
  DarkTheme.palette.primary.dark = '#00837e'

  DarkTheme.palette.secondary.main = '#001310'
  DarkTheme.palette.secondary.light = '#001310'
  DarkTheme.palette.secondary.dark = '#001310'

  return (
    <ColorModeContext.Provider
      value={{
        setMode: setColorMode,
        mode,
      }}
    >
      <ThemeProvider theme={mode === "light" ? LightTheme : DarkTheme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
