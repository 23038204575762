import React, { useState } from "react";
import {
    useTranslate,
    useTable,
    useUpdate,
    HttpError,
    useShow,
    useOne,
    useNavigation,
    CanAccess,
} from "@pankod/refine-core";
import { useModalForm } from "@pankod/refine-react-hook-form";
import {
    Grid,
    Paper,
    Typography,
    Box,
    InputBase,
    IconButton,
    Stack,
    Pagination,
    Modal,
    Fade,
    CreateButton,
    DataGrid,
    BooleanField,
    Tooltip,
    GridColumns,
    esES,
    Button,
    DateField,
} from "@pankod/refine-mui";
import { SearchOutlined, CloseOutlined, Edit, Close, Visibility, MoveToInbox, Inbox } from "@mui/icons-material";

import { Sensor, SensorZone } from "interfaces";

type SensorZonesListProps = {
  record: Sensor;
  close: () => void;
  visible: boolean;
};

export const SensorZonesList: React.FC<SensorZonesListProps> = ({
  record,
  close: modalClose,
  visible: modalVisible,
}) => {

  /*   const { dataGridProps } = useDataGrid<SensorZone>();

  console.log("dataGridProps: ", dataGridProps)
  const customerIds = [...new Set(dataGridProps.rows.map((item) => item.customerId))];

  const { data: customersData, isLoading } = useMany<Customer>({
      resource: "customers",
      ids: customerIds,
      queryOptions: {
          enabled: customerIds.length > 0,
      },
  }); */

  const mergedData: any = useOne<{ sensorZones: {
    id?: string;
    sensorId: string;
    coords: { x: number; y: number }[];
    name?: string;
    enabled?: boolean;
    currentUsage?: number;
    maxCapacity?: number;
    createdAt?: Date;
    updatedAt?: Date;
    userId?: string;
  }[] }>({
    resource: "sensors/zones",
    id: record.id
  });


/* 
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { tableQueryResult, setFilters, setCurrent, filters, pageCount } =
    useTable<SensorZone>({
      resource: "sensors/zones",
      initialPageSize: 12,
      syncWithLocation: false,
      permanentFilter: [
        {
          field: "id",
          operator: "eq",
          value: record.id,
        },
      ],
    });
     */
  /* const { data: productData } = queryResult.data?.data;

  const mergedData = productData?.data.filter((product) => (record?.id === product.sensorId)) || []; */
  /* const mergedData: any = queryResult.data; */

    const style = {
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: { xs: 380, sm: 580, md: 880, lg: 1000 },
    bgcolor: "background.paper",
    p: 2,
    my: 2,
    borderRadius: "8px",
  };

  const columns: GridColumns<SensorZone> = [
    {
      field: "enabled",
      headerName: "Estado",
      minWidth: 30,
      flex: 1,
      align: "center",
      renderCell: function render(params) {
        return (
          <BooleanField
            value={!!params.row.enabled}
            valueLabelTrue="Zona Activa"
            valueLabelFalse="Zona Inactiva"
            arrow
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Identificador/Descripción de la zona de detección",
      minWidth: 500,
      flex: 1,
      renderCell: function render(params) {
        return (
          <Tooltip title={params.row.name} arrow>
            <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{params.row.name}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Último Cambio",
      minWidth: 160,
      flex: 1,
      renderCell: function render(params) {
        return (
          <Tooltip title={params.row.name} arrow>
            <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              <DateField value={params.row.updatedAt} format="DD/MM/YYYY HH:mm:ss" />
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "currentUsage",
      headerName: "Ocupación",
      minWidth: 50,
      flex: 1,
      align: "center",
      renderCell: function render(params) {
        return (
          <Tooltip title={params.row.currentUsage} arrow>
            <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              { params.row.currentUsage }
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "maxCapacity",
      headerName: "Capacidad",
      minWidth: 50,
      flex: 1,
      align: "center",
      renderCell: function render(params) {
        return (
          <Tooltip title={params.row.maxCapacity} arrow>
            <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              { params.row.maxCapacity }
            </span>
          </Tooltip>
        );
      },
    }
  ];

  const [pageSize, setPageSize] = useState<number>(25);
  const { push } = useNavigation();

  return (
    <Modal closeAfterTransition open={modalVisible} onClose={modalClose}>
      <Fade in={modalVisible}>
        <Box sx={style}>
          <Stack
            position="absolute"
            top={12}
            right={12}
            justifyContent="center"
            padding={0}
          >
            <Tooltip title="Cerrar">
              <IconButton onClick={() => modalClose()}>
                  <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Typography variant="h5">Zonas de detección del sensor</Typography>
          <Typography marginBottom={3}>{record.address}</Typography>
          <Box sx={{height: 400}}>
            <DataGrid
              rows={mergedData.data?.data?.length >= 0 ? mergedData.data.data : []}
              columns={columns}
              localeText={{
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: "No hay zonas de detección configuradas para este sensor.",
                filterOperatorEquals: "es igual a",
                filterOperatorIsAnyOf: "es cualquiera de"
              }}
              disableSelectionOnClick
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[25, 50, 100]}
              pagination
            />
          </Box>
          <Box marginTop={3}>
            <Button onClick={() => push("/sensors/show/" + record.id)} startIcon={<Visibility />} variant="contained">
              Ver detalles del sensor
            </Button>
            <CanAccess resource="sensors/zones" action="edit">
              <Button sx={{ ml: 2 }} onClick={() => push("/sensors/zones/edit/" + record.id)} color="info" startIcon={<Edit />} variant="contained">
                Editar zonas de detección
              </Button>
            </CanAccess>
            <Button style={{float: "right"}} color="error" onClick={() => modalClose()} startIcon={<Close />}>
              Cerrar
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
