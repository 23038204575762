let apiURL;
const env: string = process.env.NODE_ENV || 'development'
if (env === 'development') {
  apiURL = "http://localhost:3001";
} else if (env === 'production') {
  apiURL = "https://pairkapi.bindevs.com";
} else {
  console.log(`NODE_ENV with value ${ env } does not have configuration`)
  process.exit(1)
}

export const dataProviderURL = apiURL;
