import { CanAccess, HttpError, useNavigation } from "@pankod/refine-core";
import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  Edit,
  Typography,
  Tooltip,
  Divider,
  Chip,
  FormControlLabel,
  Checkbox,
  Button,
} from "@pankod/refine-mui";
import { useForm, Controller, useFormState } from "@pankod/refine-react-hook-form";
import { Map, GoogleMapsAutocomplete } from "components/map";
import { dataProviderURL } from "configProvider";

import { Sensor, Customer } from "interfaces";
import { useEffect, useState } from "react";

import { io, Socket } from "socket.io-client";

import { Edit as EditIcon } from "@mui/icons-material";

const socket: Socket = io(dataProviderURL);

export const SensorEdit: React.FC = () => {
  const {
    refineCore: { formLoading, id },
    saveButtonProps,
    register,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<Sensor, HttpError, Sensor & { customer: Customer }>({
    /* shouldUnregister: false, */
    refineCoreProps: {
      action: 'edit',
      liveMode: "manual",
    }
  });

  const [emmited, setEmmited] = useState(false);

  useEffect(() => {
    if(formLoading && !emmited){
      socket.emit("resources/sensors", {data: {id, resource: "sensors"}})
      setEmmited(false);
    }
  })

  const { autocompleteProps } = useAutocomplete<Customer>({
    liveMode: "off",
    resource: "customers",
    filters: [
      {
        field: "enabled",
        operator: "eq",
        value: true,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value,
      },
    ],
  });

  const defaultProps = {
    center: {
      lat: 40.2085,
      lng: -3.713
    },
    zoom: 6,
  };

  const [mapZoom, setMapZoom] = useState(6);
  const [mapMarkerPosition, setMapMarkerPosition] = useState<google.maps.LatLng>();
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
    lat: 40.2085,
    lng: -3.713
  });

  const setMapCenterAndMarker = (address: string) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': address}, function(results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) {
      if (status == 'OK' && results) {
        setMapZoom(18);
        setMapCenter({lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()});
        setMapMarkerPosition(results[0].geometry.location);
        setValue("lat", results[0].geometry.location.lat().toString(),{
          shouldValidate: true,
          shouldDirty: true
        })
        setValue("lng", results[0].geometry.location.lng().toString(),{
          shouldValidate: true,
          shouldDirty: true
        })
        setValue("address", results[0].formatted_address, {
          shouldValidate: true,
          shouldDirty: true
        });
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  }
  

  const [map, setMap] = useState<google.maps.Map>();

  const onMapClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({location: e.latLng}).then((response) => {
        setValue("address", response.results[0].formatted_address, {
          shouldValidate: true,
          shouldDirty: true
        })
      })
      setValue("lat", e.latLng?.lat().toString(), {
        shouldValidate: true,
        shouldDirty: true
      })
      setValue("lng", e.latLng?.lng().toString(), {
        shouldValidate: true,
        shouldDirty: true
      })
      setMapMarkerPosition(e.latLng)
      setMapCenter(map?.getCenter()?.toJSON()!)
      setMapZoom(map?.getZoom()!)
    }
  };

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const onMapIdle = (m: google.maps.Map) => {
    if(!isMapLoaded && !formLoading){
      setMapCenter({lat: parseFloat(getValues().lat), lng: parseFloat(getValues().lng)});
      setMapMarkerPosition(new google.maps.LatLng(parseFloat(getValues().lat), parseFloat(getValues().lng)));
      setMapZoom(18);
      setMap(m);
      setIsMapLoaded(true);
    }
  }

  const onChangeAddress = (value: string) => {
    setValue("address", value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }
  const onChangeFeedUrl = (value: string) => {
    setValue("feedUrl", value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }
  
  const [isFeedUrlFocused, setIsFeedUrlFocused] = useState(false); 
  const [isAddressFocused, setIsAddressFocused] = useState(false); 
  const [sensorEnabled, setSensorEnabled] = useState(getValues().enabled);

  useEffect(() => {
    if (getValues().enabled != sensorEnabled) {
      setSensorEnabled(getValues().enabled)
    }
  });

  const { push } = useNavigation();

  return (
    <Edit
      footerButtons={({ defaultButtons }) => (
        <>
          <Box flex={1}>
          <CanAccess resource="sensors/zones" action="edit">
            <Button onClick={() => push("/sensors/zones/edit/" + id)} color="info" startIcon={<EditIcon />} variant="contained">
              Editar zonas de detección
            </Button>
          </CanAccess>
          </Box>
          {defaultButtons}
        </>
      )}
      breadcrumb={
        null
      }
      headerProps={{
        title: <Typography variant="h5">Editar sensor</Typography>,
      }}
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        children: "Guardar",
        /* successNotification: {type: "success", message: "El usuario se ha actualizado correctamente."} */
      }}
      deleteButtonProps={{
        children: "Eliminar sensor",
        /* errorNotification: {type: "error", message: "Se ha producido un error al eliminar el usuario."}, */
        confirmTitle: "¿Seguro que quieres eliminar este sensor?",
        confirmOkText: "Confirmar",
        confirmCancelText: "Volver"
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <input type="hidden" {...register("sensorType")} />

        <Controller
          control={control}
          name="address"
          defaultValue=""
          render={({ field }) => {
            return (
              <TextField
                {...field}
                {...register("address", { required: "Este campo es obligatorio" })}
                onChange={(value) => onChangeAddress(value.target.value)}
                error={!!errors?.address}
                helperText={errors.address?.message}
                margin="normal"
                required
                onFocus={() => setIsAddressFocused(true)}
                onBlur={() => setIsAddressFocused(false)}
                value={field.value || ''}
                InputLabelProps={{ shrink: field.value || isAddressFocused ? true : false }}
                id="address"
                label="Dirección/Identificador del sensor"
                placeholder="💡 Escribe la dirección exacta del sensor o un nombre que lo identifique"
              />
            )
          }}
        />

        <Box sx={{
          display: 'grid',
          gridTemplateColumns: { sm: '0.5fr 0.5fr' },
          gap: 2,
        }}>
          <Controller
            control={control}
            name="lat"
            defaultValue=""
            render={({ field }) => {
              return (
                <TextField
                  {...register("lat", { required: "Este campo es obligatorio, selecciona una ubicación en el mapa para continuar" })}
                  {...field}
                  value={field.value || ''}
                  InputLabelProps={{ shrink: field.value ? true : false }}
                  disabled
                  required
                  label="Latitud"
                  error={!!errors?.lat}
                  helperText={errors.lat?.message}
                  margin="normal"
                />
              )
            }}
          />
          <Controller
            control={control}
            name="lng"
            defaultValue=""
            render={({ field }) => {
              return (
                <TextField
                  {...register("lng", { required: "Este campo es obligatorio, selecciona una ubicación en el mapa para continuar" })}
                  {...field}
                  value={field.value || ''}
                  InputLabelProps={{ shrink: field.value ? true : false }}
                  disabled
                  required
                  label="Longitud"
                  error={!!errors?.lng}
                  helperText={errors.lng?.message}
                  margin="normal"
                />
              )
            }}
          />
        </Box>

        <Typography marginTop={2} variant="h6">💡 Para modificar la dirección del sensor, busca la nueva dirección y después haz click en la ubicación exacta en el mapa:</Typography>
        <GoogleMapsAutocomplete
          margin="normal"
          label="Buscar dirección..."
          id="searchAddress"
          setMapCenterAndMarker={setMapCenterAndMarker}
          noOptionsText="Escribe una dirección válida"
          clearText="Borrar dirección"
          openText="Mostrar resultados"
          closeText="Ocultar resultados"
          loadingText="Cargando..."
        />

        <Box sx={{ height: "576px", width: "100%", position: "relative" }}>
          <Map
            {...defaultProps}
            onClick={onMapClick}
            clickableIcons={false}
            streetViewControl={false}
            fullscreenControl={false}
            onIdle={onMapIdle}
            center={mapCenter}
            zoom={mapZoom}
            mapMarkerPosition={mapMarkerPosition}
          />
        </Box>

        <Controller
          control={control}
          name="feedUrl"
          defaultValue=""
          render={({ field }) => {
            return (
              <TextField
                {...field}
                {...register("feedUrl", { required: "Este campo es obligatorio" })}
                error={!!errors?.feedUrl}
                helperText={errors.feedUrl?.message}
                margin="normal"
                required
                id="feedUrl"
                label="URL de fuente de vídeo"
                placeholder="💡 Escribe la dirección URL de la fuente de vídeo del sensor"
                onChange={(value) => onChangeFeedUrl(value.target.value)}
                onFocus={() => setIsFeedUrlFocused(true)}
                onBlur={() => setIsFeedUrlFocused(false)}
                value={field.value || ''}
                InputLabelProps={{ shrink: field.value || isFeedUrlFocused ? true : false }}
              />
            )
          }}
        />

        <CanAccess
          key={"sensorsCustomerEditField"}
          resource={"sensors"}
          action="field"
        >
          <Controller
            control={control}
            name="customer"
            rules={{ required: "Este campo es obligatorio" }}
            render={({ field }) => (
              <Autocomplete
                {...autocompleteProps}
                {...field}
                value={field.value || null}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                filterOptions={(options, state) => {
                  let newOptions: Customer[] = [];
                  options.forEach((element) => {
                    if (
                      element.name
                        .replace(",", "")
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    )
                    newOptions.push(element);
                  });
                  return newOptions;
                }}
                getOptionLabel={(item) => {
                  return item.name ? item.name : "";
                }}
                isOptionEqualToValue={(option, value) =>
                  value === undefined || option.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente"
                    margin="normal"
                    variant="outlined"
                    error={!!errors.customer}
                    helperText={errors.customer?.message}
                    required
                  />
                )}
                noOptionsText="No hay resultados de clientes"
                clearText="Borrar resultados"
                openText="Mostrar resultados"
                closeText="Ocultar resultados"
                loadingText="Cargando..."
              />
            )}
          />
        </CanAccess>

        <FormControlLabel
          style={{ pointerEvents: "none" }}
          control={<Checkbox
            {...register("enabled")}
            id="enabled"
            title="Activo"
            name="enabled"
            checked={ !!getValues().enabled }
            onClick={() => {
              setSensorEnabled(!sensorEnabled);
            }}
            style={{ pointerEvents: "auto" }} 
          />}
          label="Activo"
        />

      </Box>
    </Edit>
  );
};
