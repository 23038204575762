import React, { useContext } from "react";
import { useGetIdentity } from "@pankod/refine-core";
import { AppBar, Stack, Toolbar, Typography, Avatar, Box, IconButton, Tooltip } from "@pankod/refine-mui";
import type { RefineLayoutHeaderProps } from "@pankod/refine-mui";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { ColorModeContext } from "../../contexts";

export const Header: React.FC<RefineLayoutHeaderProps> = () => {
  const { data: user } = useGetIdentity();

  const shouldRenderHeader = user && (user.name || user.avatar);
  const { mode, setMode } = useContext(ColorModeContext);

  return shouldRenderHeader ? (
    <AppBar color="default" position="sticky" elevation={1}>
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Stack
            direction="row"
            gap="16px"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subtitle2">{user?.name}</Typography>
            { user?.avatar ? <Avatar src={user?.avatar} alt={user?.name} /> : null }
          </Stack>
          <Box marginLeft="10px">
            <IconButton
              onClick={() => {
                setMode();
              }}
            >
              {mode === "dark" ? (
                <Tooltip title="Cambiar a modo claro">
                  <LightModeOutlined />
                </Tooltip>
              ) : (
                <Tooltip title="Cambiar a modo oscuro">
                  <DarkModeOutlined />
                </Tooltip>
              )}
            </IconButton>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
  ) : null;
};
