import { DeleteOutline, Inbox, MoveToInbox } from "@mui/icons-material";
import {
  BooleanField,
  Box,
  Checkbox,
  DataGrid,
  DateField,
  esES,
  FormControlLabel,
  GridColumns,
  IconButton,
  List,
  TextField,
  Tooltip,
} from "@pankod/refine-mui";
import { axiosInstance } from "App";
import { dataProviderURL } from "configProvider";
import { SensorZone } from "interfaces";
import { useEffect, useState } from "react";

export default function SquareZoneCanvas(props: any) {
  //const [currentSquare, setCurrentSquare] = useState<{x: number, y: number}[]>([]);
  const [squareList, setSquareList] = useState<
    {
      id?: string;
      sensorId: string;
      coords: { x: number; y: number }[];
      name?: string;
      enabled?: boolean;
      currentUsage?: number;
      maxCapacity?: number;
      createdAt?: Date;
      updatedAt?: Date;
      userId?: string;
    }[]
  >([]);

  const [detectionsList, setDetectionsList] = useState<
    {
      id?: string;
      sensorZoneId: string;
      x1: number;
      y1: number;
      x2: number;
      y2: number;
      createdAt?: Date;
    }[]
  >([]);
  //const [posAnterior, setPosAnterior] = useState<{x: number, y: number} | null>(null);
  //const [count, setCount] = useState<number>(0);
  const [currentNumber, setCurrentNumber] = useState<number>(0);
  const [currentDetectionNumber, setCurrentDetectionNumber] = useState<number>(0);
  const [editingModeLoaded, setEditingModeLoaded] = useState<boolean>(false);
  const [photoLoaded, setPhotoLoaded] = useState<boolean>(false);

  var currentSquare: {
    id?: string;
    sensorId: string;
    coords: { x: number; y: number }[];
    name?: string;
    enabled?: boolean;
    currentUsage?: number;
    maxCapacity?: number;
    createdAt?: Date;
    updatedAt?: Date;
    userId?: string;
  } = { sensorId: props.sensorId, coords: [] };


  useEffect(() => {
    if (props.sensorId) {
      if (props.editing) {
        if (editingModeLoaded) {
          //return;
        }
        setEditingModeLoaded(true);
      }

      can = document.getElementById("canvas")! as HTMLCanvasElement;
      if (props.readOnly && props.readOnly === false || !props.readOnly) {
        can.onclick = handleClick;
        can.onmousemove = handleMouseMove;
      }


      var reader = new FileReader();
      reader.onload = () => {
        let base64string: any = reader.result;
        //console.log("base64string", base64string);
        if (can) {
          can.style.backgroundImage = 'url("' + base64string + '")';
        }

      }

      //if (!photoLoaded) {
      axiosInstance.get(`${dataProviderURL}/sensors/photos/${props.sensorId}`, { responseType: "blob" }).then((response) => {
        reader.readAsDataURL(response.data);
      }).catch((error) => {
        throw error;
      });
      setPhotoLoaded(true);
      //}

      ctx = can.getContext("2d")! as CustomCanvasRenderingContext2D;
      if (props.zones.length != 0) {
        setSquareList(props.zones);
        if (currentNumber == 0) {
          setCurrentNumber(props.zones.length);
        }

      }

      if (props.detections) {
        setDetectionsList(props.detections);
        if (currentDetectionNumber == 0) {
          setCurrentDetectionNumber(props.detections.length);
        }

      }

      drawLine(0, 0, 0, 0, true);
      updateList();
    }
  });

  //var squareList: {x: number, y: number}[][] = [];
  var posAnterior: { x: number; y: number } | null;
  var count: number = 0;

  var can: HTMLCanvasElement;

  class CustomCanvasRenderingContext2D extends CanvasRenderingContext2D {
    /*constructor(){
          super();
          this.roundRect = this.roundRect.bind(this);
      }*/

    roundRect(x, y, w, h, r) {
      if (w < 2 * r) r = w / 2;
      if (h < 2 * r) r = h / 2;
      this.beginPath();
      this.moveTo(x + r, y);
      this.arcTo(x + w, y, x + w, y + h, r);
      this.arcTo(x + w, y + h, x, y + h, r);
      this.arcTo(x, y + h, x, y, r);
      this.arcTo(x, y, x + w, y, r);
      this.closePath();
      return this;
    }
  }

  var ctx: CustomCanvasRenderingContext2D;

  function handleClick(event: MouseEvent) {

    let x = event.pageX - (event.target as HTMLCanvasElement).offsetLeft;
    let y = event.pageY - (event.target as HTMLCanvasElement).offsetTop;

    currentSquare.coords[count] = { x, y };
    //setCount(count + 1);
    count++;

    if (count == 4) {
      if (
        !intersects(
          currentSquare.coords[0].x,
          currentSquare.coords[0].y,
          currentSquare.coords[1].x,
          currentSquare.coords[1].y,
          currentSquare.coords[2].x,
          currentSquare.coords[2].y,
          currentSquare.coords[3].x,
          currentSquare.coords[3].y
        ) &&
        !intersects(
          currentSquare.coords[1].x,
          currentSquare.coords[1].y,
          currentSquare.coords[2].x,
          currentSquare.coords[2].y,
          currentSquare.coords[3].x,
          currentSquare.coords[3].y,
          currentSquare.coords[0].x,
          currentSquare.coords[0].y
        )
        &&
        calcPolygonArea(currentSquare.coords) > 0
      ) {
        //if (posAnterior) {

        currentSquare.name = "Zona # " + (currentNumber + 1);
        currentSquare.maxCapacity = 1;
        currentSquare.enabled = true;
        currentSquare.id = crypto.randomUUID();

        setCurrentNumber(currentNumber + 1);

        setSquareList((previousSquareList) => [
          ...previousSquareList,
          currentSquare,
        ]);

        /*
                let sensorZone = {
                  sensorId: props.sensorId, 
                  x1: currentSquare.coords[0].x,
                  y1: currentSquare.coords[0].y,
                  x2: currentSquare.coords[1].x,
                  y2: currentSquare.coords[1].y,
                  x3: currentSquare.coords[2].x,
                  y3: currentSquare.coords[2].y,
                  x4: currentSquare.coords[3].x,
                  y4: currentSquare.coords[3].y,
                  id: crypto.randomUUID(),
                  name: "Sensor # " + squareList.length,
                  enabled: true
                };
                */
        props.callbackUpdate((previousSensorZoneData) => [
          ...previousSensorZoneData,
          currentSquare,
        ]);
      }

      currentSquare = { sensorId: props.sensorId, coords: [] };
      posAnterior = null;
      //setCurrentSquare([]);
      //setPosAnterior(null);
      //setCount(0);
      count = 0;
      updateList();
      drawLine(0, 0, 0, 0, true);
    }

    if (count > 0) {
      posAnterior = { x, y };
      //setPosAnterior({ x, y });
    }
  }

  function calcPolygonArea(vertices) {
    var total = 0;

    for (var i = 0, l = vertices.length; i < l; i++) {
      var addX = vertices[i].x;
      var addY = vertices[i == vertices.length - 1 ? 0 : i + 1].y;
      var subX = vertices[i == vertices.length - 1 ? 0 : i + 1].x;
      var subY = vertices[i].y;

      total += (addX * addY * 0.5);
      total -= (subX * subY * 0.5);
    }

    return Math.abs(total);
  }

  function intersects(a, b, c, d, p, q, r, s) {
    let det, gamma, lambda;
    det = (c - a) * (s - q) - (r - p) * (d - b);
    if (det === 0) {
      return false;
    } else {
      lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
      gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
      return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
    }
  }

  function handleMouseMove(event: MouseEvent) {
    let x = event.pageX - (event.target as HTMLCanvasElement).offsetLeft;
    let y = event.pageY - (event.target as HTMLCanvasElement).offsetTop;
    if (posAnterior) {
      drawLine(posAnterior.x, posAnterior.y, x, y);
    }
  }

  function setSensorEnabled(id: string) {
    let temp = [...squareList];
    temp.forEach((elem) => {
      if (elem.id === id) {
        elem.enabled = !elem.enabled;
      }
    });
    setSquareList((prevSquareList) => temp);
    props.callbackUpdate((prevSensorZoneData) => temp);
    updateList();
    drawLine(0, 0, 0, 0, true);
  }

  function updateZoneName(id: string, value: string) {
    let temp = [...squareList];
    temp.forEach((elem) => {
      if (elem.id === id) {
        const date = new Date();
        const formattedDate = `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;

        elem.name = value.length === 0 ? "Zona " + formattedDate : value;
      }
    });

    setSquareList((prevSquareList) => temp);
    //props.callbackUpdate((prevSensorZoneData) => temp);
    updateList();
    drawLine(0, 0, 0, 0, true);
    return false;
  }

  function updateZoneMaxCapacity(id: string, value: number) {
    let temp = [...squareList];
    temp.forEach((elem) => {
      if (elem.id === id) {
        elem.maxCapacity = value <= 0 ? 1 : value;
      }
    });

    setSquareList((prevSquareList) => temp);
    //props.callbackUpdate((prevSensorZoneData) => temp);
    updateList();
    drawLine(0, 0, 0, 0, true);
    return false;
  }

  function deleteSquare(id: string) {
    let temp = [...squareList];

    let i = 0;
    let idToDelete = -1;
    temp.forEach((elem) => {
      if (elem.id === id) {
        idToDelete = i;
      }
      i++;
    });

    temp.splice(idToDelete, 1);

    setSquareList((prevSquareList) => temp);
    props.callbackUpdate((prevSensorZoneData) => temp);
    updateList();
    drawLine(0, 0, 0, 0, true);
  }

  function updateList() {
    if (props.setSensorZones) {
      props.setSensorZones();
    }
  }

  const occupiedColor = "red";
  const availableColor = "green";
  const disabledColor = "grey";
  const detectionColor = "yellow";

  function drawLine(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    ignore: boolean = false
  ) {
    ctx.clearRect(0, 0, can.width, can.height);

    let i = 1;
    try {
      squareList.forEach((cuadrado) => {
        ctx.beginPath();
        ctx.moveTo(cuadrado.coords[0].x, cuadrado.coords[0].y);
        ctx.lineTo(cuadrado.coords[1].x, cuadrado.coords[1].y);
        ctx.lineTo(cuadrado.coords[2].x, cuadrado.coords[2].y);
        ctx.lineTo(cuadrado.coords[3].x, cuadrado.coords[3].y);
        ctx.closePath();
        stroke(cuadrado.enabled ? (cuadrado.currentUsage === cuadrado.maxCapacity ? occupiedColor : availableColor) : disabledColor);
      });
    } catch (e) { }

    try {
      detectionsList.forEach((cuadrado) => {
        ctx.beginPath();
        ctx.moveTo(cuadrado.x1, cuadrado.y1);
        ctx.lineTo(cuadrado.x1, cuadrado.y2);
        ctx.lineTo(cuadrado.x2, cuadrado.y2);
        ctx.lineTo(cuadrado.x2, cuadrado.y1);
        ctx.closePath();
        stroke(detectionColor);
      });
    } catch (e) { }

    if (count > 1) {
      ctx.beginPath();
      ctx.moveTo(currentSquare.coords[0].x, currentSquare.coords[0].y);
      for (i = 1; i < currentSquare.coords.length; i++) {
        ctx.lineTo(currentSquare.coords[i].x, currentSquare.coords[i].y);
      }
      stroke(disabledColor);
    }

    if (!ignore) {
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.closePath();
      stroke(disabledColor);
    }

    i = 1;
    try {
      squareList.forEach((cuadrado) => {
        ctx.beginPath();
        printSquareId(cuadrado, i);
        i++;
      });
    } catch (e) { }
  }

  function stroke(changeColor: string) {
    ctx.lineCap = "round";
    ctx.strokeStyle = changeColor;
    ctx.lineWidth = 5;
    ctx.stroke();
    ctx.strokeStyle = "white";
    ctx.lineWidth = 2;
    ctx.stroke();
  }

  function printSquareId(cuadrado, id: number) {
    ctx.strokeStyle = disabledColor;
    if (cuadrado.enabled) {
      ctx.strokeStyle = cuadrado.currentUsage === cuadrado.maxCapacity ? occupiedColor : availableColor;
    }

    let text = cuadrado.name;

    let x = cuadrado.coords.map((a) => {
      return a.x;
    });
    let y = cuadrado.coords.map((a) => {
      return a.y;
    });
    let minX = Math.min.apply(null, x);
    let maxX = Math.max.apply(null, x);
    let minY = Math.min.apply(null, y);
    let maxY = Math.max.apply(null, y);
    let pos = [(minX + maxX) / 2, (minY + maxY) / 2];

    let width = ctx.measureText(text).width;

    ctx.textAlign = "center";

    ctx.fillStyle = "white";
    ctx.roundRect(
      pos[0] - width / 2 - 4,
      pos[1] - parseInt(ctx.font, 10) / 2 - 8,
      width + 8,
      parseInt(ctx.font, 10) + 8,
      8
    );
    ctx.fill();

    ctx.fillStyle = "black";
    ctx.fillText(text, pos[0], pos[1]);
  }

  const editColumns: GridColumns<SensorZone> = [
    {
      field: "enabled",
      headerName: "Acciones",
      minWidth: 20,
      flex: 1,
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: function render(params) {
        return (
          <Box>
            <Tooltip title="Borrar zona de detección">
              <IconButton
                color="error"
                onClick={() => {
                  deleteSquare(params.row.id);
                }}
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
            <Tooltip title="Activar/desactivar zona">
              <FormControlLabel
                style={{ pointerEvents: "none" }}
                control={
                  <Checkbox
                    checked={!!params.row.enabled}
                    onClick={() => {
                      setSensorEnabled(params.row.id);
                    }}
                    style={{ pointerEvents: "auto" }}
                  />
                }
                label="Activar zona"
              />
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Identificador/Descripción de la zona de detección",
      minWidth: 700,
      flex: 1,
      renderCell: function render(params) {
        return (
          <TextField
            margin="normal"
            required
            fullWidth
            label="Identificador"
            defaultValue={params.row.name}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => {
              updateZoneName(params.row.id, e.target.value);
            }}
            onBlur={() => {
              props.callbackUpdate((prevSensorZoneData) => [...squareList]);
            }}
          />
        );
      },
    },
    {
      field: "maxCapacity",
      headerName: "Capacidad",
      minWidth: 20,
      flex: 1,
      align: "center",
      renderCell: function render(params) {
        return (
          <TextField
            margin="normal"
            type="number"
            required
            fullWidth
            label="Capacidad (Nº Vehículos)"
            defaultValue={params.row.maxCapacity}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => {
              updateZoneMaxCapacity(params.row.id, Number(e.target.value))
            }}
            onBlur={() => {
              props.callbackUpdate((prevSensorZoneData) => [...squareList])
            }}
          />
        );
      },
    }
  ];

  const showColumns: GridColumns<SensorZone> = [
    {
      field: "enabled",
      headerName: "Estado",
      minWidth: 20,
      flex: 1,
      align: "center",
      renderCell: function render(params) {
        return (
          <BooleanField
            value={!!params.row.enabled}
            valueLabelTrue="Zona Activa"
            valueLabelFalse="Zona Inactiva"
            arrow
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Identificador/Descripción de la zona de detección",
      minWidth: 700,
      flex: 1,
      renderCell: function render(params) {
        return (
          <Tooltip title={params.row.name} arrow>
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.name}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Último Cambio",
      minWidth: 200,
      flex: 1,
      renderCell: function render(params) {
        return (
          <Tooltip title={params.row.name} arrow>
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <DateField value={params.row.updatedAt} format="DD/MM/YYYY HH:mm:ss" />
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "currentUsage",
      headerName: "Ocupación",
      minWidth: 50,
      flex: 1,
      align: "center",
      renderCell: function render(params) {
        return (
          <Tooltip title={params.row.currentUsage} arrow>
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {params.row.currentUsage}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "maxCapacity",
      headerName: "Capacidad",
      minWidth: 50,
      flex: 1,
      align: "center",
      renderCell: function render(params) {
        return (
          <Tooltip title={params.row.maxCapacity} arrow>
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.maxCapacity}</span>
          </Tooltip>
        );
      },
    }
  ];

  const [pageSize, setPageSize] = useState<number>(25);

  return (
    <div>
      <canvas
        id="canvas"
        width={props.width}
        height={props.height}
        className="image-canvas"
        style={{
          //backgroundImage: `url(${props.imageUrl})`,
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "25px"
        }}
      ></canvas>
      <div id="square-list" style={{ display: "none" }}></div>
      <div>
        <DataGrid
          rows={props.zones}
          columns={(props.readOnly && props.readOnly === false || !props.readOnly) ? editColumns : showColumns}
          getRowHeight={() => (props.readOnly && props.readOnly === false || !props.readOnly) ? "auto" : null}
          localeText={{
            ...esES.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: (
              props.readOnly && props.readOnly === false || !props.readOnly ? "No hay zonas de detección configuradas para este sensor. Dibuja nuevas áreas haciendo click en 4 puntos de la imagen anterior para añadirlas a este listado."
                :
                "No hay zonas de detección configuradas para este sensor."
            ),
            filterOperatorEquals: "es igual a",
            filterOperatorIsAnyOf: "es cualquiera de"
          }}
          autoHeight
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
          pagination
        />
      </div>
    </div>
  );
}
