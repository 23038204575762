import { CanAccess, HttpError } from "@pankod/refine-core";
import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  Create,
  Typography,
  IconButton,
  Tooltip,
} from "@pankod/refine-mui";
import { useForm, Controller } from "@pankod/refine-react-hook-form";
import { Map, GoogleMapsAutocomplete } from "components/map";
import SearchIcon from '@mui/icons-material/Search';

import { Sensor, Customer } from "interfaces";
import { useState } from "react";

export const SensorCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<Sensor, HttpError, Sensor & { customer: Customer }>();

  const { autocompleteProps } = useAutocomplete<Customer>({
    resource: "customers",
    filters: [
      {
        field: "enabled",
        operator: "eq",
        value: true,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value,
      },
    ],
  });

  const defaultProps = {
    center: {
      lat: 40.2085,
      lng: -3.713
    },
    zoom: 6,
  };

  const [mapZoom, setMapZoom] = useState(6);
  const [mapMarkerPosition, setMapMarkerPosition] = useState<google.maps.LatLng>();
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
    lat: 40.2085,
    lng: -3.713
  });

  const setMapCenterAndMarker = (address: string) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': address}, function(results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) {
      if (status == 'OK' && results) {
        setMapZoom(18);
        setMapCenter({lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()});
        setMapMarkerPosition(results[0].geometry.location);
        setValue("lat", results[0].geometry.location.lat().toString(),{
          shouldValidate: true,
          shouldDirty: true
        })
        setValue("lng", results[0].geometry.location.lng().toString(),{
          shouldValidate: true,
          shouldDirty: true
        })
        setValue("address", results[0].formatted_address, {
          shouldValidate: true,
          shouldDirty: true
        });
        setValue("sensorType", JSON.parse(process.env.REACT_APP_DEFAULT_SENSOR_TYPE!))
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  }
  

  const [map, setMap] = useState<google.maps.Map>();

  const onMapClick = (e: google.maps.MapMouseEvent) => {
    //console.log(e.latLng?.lat(), e.latLng?.lng());
    if (e.latLng) {
      //fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=40.94443102832244,-4.116387383176132&key=AIzaSyCvf4M-_G3Iyu67iUPhhJ6tIObIzrZUS5Q").then(response => {response.json()}).then(data => {console.log(data);});
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({location: e.latLng}).then((response) => {
        setValue("address", response.results[0].formatted_address, {
          shouldValidate: true,
          shouldDirty: true
        })
      })

      setValue("lat", e.latLng?.lat().toString(), {
        shouldValidate: true,
        shouldDirty: true
      })
      setValue("lng", e.latLng?.lng().toString(), {
        shouldValidate: true,
        shouldDirty: true
      })
      setMapMarkerPosition(e.latLng)
      setMapCenter(map?.getCenter()?.toJSON()!)
      setMapZoom(map?.getZoom()!)
      setValue("sensorType", JSON.parse(process.env.REACT_APP_DEFAULT_SENSOR_TYPE!))
    }
  };

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const onMapIdle = (m: google.maps.Map) => {
    if(!isMapLoaded){
      setMap(m)
      setIsMapLoaded(true)
    }
  }

  const onChangeAddress = (value: string) => {
    setValue("address", value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const [isAddressFocused, setIsAddressFocused] = useState(false); 

  return (
    <Create
      breadcrumb={
        null /* <Breadcrumb breadcrumbProps={{ separator: "/" }} /> */
      }
      headerProps={{
        title: <Typography variant="h5">Añadir nuevo sensor</Typography>,
      }}
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, children: "Guardar" }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Typography variant="h6">💡 Escribe la dirección del sensor y después haz click en la ubicación exacta en el mapa:</Typography>
        <GoogleMapsAutocomplete
          margin="normal"
          label="Buscar dirección..."
          id="searchAddress"
          setMapCenterAndMarker={setMapCenterAndMarker}
          autoFocus
          noOptionsText="Escribe una dirección válida"
          clearText="Borrar dirección"
          openText="Mostrar resultados"
          closeText="Ocultar resultados"
          loadingText="Cargando..."
        />

        <input type="hidden" defaultValue={""} {...register("sensorType")} />

        {/* <GoogleMapsAutocomplete
          register={{...register("address", { required: "Este campo es obligatorio" })}}
          error={!!errors?.address}
          helperText={errors.address?.message}
          margin="normal"
          label="Dirección del sensor"
          id="address"
          name="address"
          autoFocus
        /> */}

        {/* <TextField
          {...register("address", { required: "Este campo es obligatorio" })}
          error={!!errors?.address}
          helperText={errors.address?.message}
          margin="normal"
          required
          id="address"
          label="Dirección del sensor"
          placeholder="💡 Escribe la dirección del sensor y pulsa enter o Buscar para seleccionar la ubicación exacta en el mapa"
          name="address"
          autoFocus
          InputProps={{
            endAdornment:
            <Tooltip title="Buscar dirección">
              <IconButton onClick={()=>0}><SearchIcon /></IconButton>
            </Tooltip>
          }}
        /> */}

        <Box sx={{ height: "576px", width: "100%", position: "relative" }}>
          <Map
            {...defaultProps}
            onClick={onMapClick}
            clickableIcons={false}
            streetViewControl={false}
            fullscreenControl={false}
            onIdle={onMapIdle}
            center={mapCenter}
            zoom={mapZoom}
            mapMarkerPosition={mapMarkerPosition}
          />
        </Box>

        <Controller
          control={control}
          name="address"
          defaultValue=""
          render={({ field }) => {
            return (
              <TextField
                {...field}
                {...register("address", { required: "Este campo es obligatorio" })}
                onChange={(value) => onChangeAddress(value.target.value)}
                error={!!errors?.address}
                helperText={errors.address?.message}
                margin="normal"
                required
                onFocus={() => setIsAddressFocused(true)}
                onBlur={() => setIsAddressFocused(false)}
                value={field.value || ''}
                InputLabelProps={{ shrink: field.value || isAddressFocused ? true : false }}
                id="address"
                label="Dirección/Identificador del sensor"
                placeholder="💡 Escribe la dirección exacta del sensor o un nombre que lo identifique"
              />
            )
          }}
        />

        <Box sx={{
          display: 'grid',
          gridTemplateColumns: { sm: '0.5fr 0.5fr' },
          gap: 2,
        }}>
          <Controller
            control={control}
            name="lat"
            defaultValue=""
            render={({ field }) => {
              return (
                <TextField
                  {...register("lat", { required: "Este campo es obligatorio, selecciona una ubicación en el mapa para continuar" })}
                  {...field}
                  value={field.value || ''}
                  InputLabelProps={{ shrink: field.value ? true : false }}
                  disabled
                  required
                  label="Latitud"
                  error={!!errors?.lat}
                  helperText={errors.lat?.message}
                  margin="normal"
                />
              )
            }}
          />
          <Controller
            control={control}
            name="lng"
            defaultValue=""
            render={({ field }) => {
              return (
                <TextField
                  {...register("lng", { required: "Este campo es obligatorio, selecciona una ubicación en el mapa para continuar" })}
                  {...field}
                  value={field.value || ''}
                  InputLabelProps={{ shrink: field.value ? true : false }}
                  disabled
                  required
                  label="Longitud"
                  error={!!errors?.lng}
                  helperText={errors.lng?.message}
                  margin="normal"
                />
              )
            }}
          />
        </Box>

        <TextField
          {...register("feedUrl", { required: "Este campo es obligatorio" })}
          error={!!errors?.feedUrl}
          helperText={errors.feedUrl?.message}
          margin="normal"
          required
          id="feedUrl"
          label="URL de fuente de vídeo"
          placeholder="💡 Escribe la dirección URL de la fuente de vídeo del sensor"
          name="feedUrl"
        />

        <CanAccess
          key={"sensorsCustomerCreateField"}
          resource={"sensors"}
          action="field"
        >
          <Controller
            control={control}
            name="customer"
            rules={{ required: "Este campo es obligatorio" }}
            render={({ field }) => (
              <Autocomplete
                {...autocompleteProps}
                {...field}
                value={field.value || null}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                filterOptions={(options, state) => {
                  let newOptions: Customer[] = [];
                  options.forEach((element) => {
                    if (
                      element.name
                        .replace(",", "")
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    )
                    newOptions.push(element);
                  });
                  return newOptions;
                }}
                getOptionLabel={(item) => {
                  return item.name ? item.name : "";
                }}
                isOptionEqualToValue={(option, value) =>
                  value === undefined || option.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente"
                    margin="normal"
                    variant="outlined"
                    error={!!errors.customer}
                    helperText={errors.customer?.message}
                    required
                  />
                )}
              />
            )}
          />
        </CanAccess>
      </Box>
    </Create>
  );
};
