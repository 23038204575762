import { HttpError } from "@pankod/refine-core";
import {
  Box,
  TextField,
  Create,
  Typography,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

import { Role } from "interfaces";

export const RoleCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    formState: { errors },
  } = useForm<Role, HttpError, Role>();

  return (
    <Create
      breadcrumb={
        null /* <Breadcrumb breadcrumbProps={{ separator: "/" }} /> */
      }
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, children: "Crear Rol" }}
      headerProps={{
        title: <Typography variant="h5">Crear nuevo rol de usuario</Typography>,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("roleName", { required: "Este campo es obligatorio" })}
          error={!!errors?.roleName}
          helperText={errors.roleName?.message}
          margin="normal"
          required
          fullWidth
          id="roleName"
          label="Rol"
          name="roleName"
          autoFocus
        />
        <TextField
          {...register("access", { required: "Este campo es obligatorio" })}
          error={!!errors?.access}
          helperText={errors.access?.message}
          margin="normal"
          required
          fullWidth
          id="access"
          label="Acceso"
          name="access"
        />
      </Box>
    </Create>
  );
};
