import { Refine } from "@pankod/refine-core";
import {
  //Layout,
  ErrorComponent,
  ReadyPage,
  //LightTheme,
  CssBaseline,
  //ThemeProvider,
  GlobalStyles,
  RefineSnackbarProvider,
  notificationProvider,
  AppBar,
  Stack,
  Box,
  IconButton,
} from "@pankod/refine-mui";
import routerProvider from "@pankod/refine-react-router-v6";
//import dataProvider from "@pankod/refine-simple-rest";
import dataProvider from "@pankod/refine-nestjsx-crud";

import { ColorModeContextProvider, ColorModeContext } from "./contexts";
//import { PostList, PostShow, PostEdit, PostCreate } from "pages/sensors";
import { UserCreate, UserList, UserEdit, UserShow } from "pages/users";
import { CustomerCreate, CustomerEdit, CustomerList, CustomerShow } from "pages/customers";
import { SensorCreate, SensorEdit, SensorList, SensorShow } from "pages/sensors";
import { SensorZonesEdit } from "pages/sensor-zones";
import { Apartment, DarkModeOutlined, LightModeOutlined, LinkedCamera, People, Lock } from "@mui/icons-material";
import { useContext } from "react";
import { Title } from "components/title";
import { liveProvider } from "liveProvider";
import { authProvider } from "authProvider";
import { RoleList, RoleCreate, RoleEdit, RoleShow } from "pages/roles";
import { AuthPage } from "components/pages/auth";
import { Layout } from "components/layout";
import { Header } from "components/layout/header";

import axios from "axios";
export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    // Here we can perform any function we'd like on the request
    (request: any) => {
        // Retrieve the token from local storage
        //const token = JSON.parse(localStorage.getItem("auth") || "");
        const token = localStorage.getItem("auth") || "";
        // Check if the header property exists
        if (token != "") {
          if (request.headers) {
            // Set the Authorization header if it exists
            request.headers[
              "Authorization"
            ] = `Bearer ${token}`;
          } else {
            // Create the headers property if it does not exist
            request.headers = {
              Authorization: `Bearer ${token}`,
            };
          }
        }
        return request;
    },
);

import { useLogout } from "@pankod/refine-core";
import { accessControlProvider } from "accessControlProvider";
import { dataProviderURL } from "configProvider";

export const LogoutButton = () => {
    const { mutate: logout } = useLogout();

    return <button onClick={() => logout()}>Desconectar</button>;
};

const CustomTitle = ({ collapsed }: any) => (
  <div>{collapsed ? "Pairk" : "Pairk - Smart City Solution"}</div>
);

/*const Header = () => {
  const { mode, setMode } = useContext(ColorModeContext);
  return (
      <AppBar color="default" position="sticky">
          <Stack width="100%" direction="row" justifyContent="end">
              <Box marginRight="20px">
                  <IconButton
                      onClick={() => {
                          setMode();
                      }}
                  >
                      {mode === "dark" ? (
                          <LightModeOutlined />
                      ) : (
                          <DarkModeOutlined />
                      )}
                  </IconButton>
              </Box>
          </Stack>
      </AppBar>
  );
};*/
/* 
const getDataProviderURL = () => {
  const env: string = process.env.NODE_ENV || 'development'
  if (env === 'development') {
    return "http://localhost:3001";
  } else if (env === 'production') {
    return "https://pairkapi.bindevs.com";
  } else {
    console.log(`NODE_ENV with value ${ env } have not configuration`)
    process.exit(1)
  }
};

let apiURL;
const env: string = process.env.NODE_ENV || 'development'
if (env === 'development') {
  apiURL = "http://localhost:3001";
} else if (env === 'production') {
  apiURL = "https://pairkapi.bindevs.com";
} else {
  console.log(`NODE_ENV with value ${ env } have not configuration`)
  process.exit(1)
}

export const dataProviderURL = apiURL; */

const App: React.FC = () => {
  return (
    <ColorModeContextProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          routerProvider={routerProvider}
          authProvider={authProvider}
          dataProvider={dataProvider(dataProviderURL, axiosInstance)}
          liveProvider={liveProvider()}
          notificationProvider={notificationProvider}
          options={{
            liveMode: "auto",
            disableTelemetry: true
          }}
          Layout={Layout}
          ReadyPage={ReadyPage}
          LoginPage={AuthPage}
          catchAll={<ErrorComponent />}
          Header={Header}
          Title={Title}
          accessControlProvider={accessControlProvider}
          resources={[
            /* {
              name: "posts",
              list: PostList,
              show: PostShow,
              edit: PostEdit,
              create: PostCreate,
              canDelete: true,
            }, */
            {
              name: "sensors",
              list: SensorList,
              show: SensorShow,
              create: SensorCreate,
              edit: SensorEdit,
              canDelete: true,
              icon: <LinkedCamera titleAccess="Sensores" />,
              options: { label: "Sensores" }
            },
            {
              name: "customers",
              list: CustomerList,
              show: CustomerShow,
              create: CustomerCreate,
              edit: CustomerEdit,
              canDelete: true,
              icon: <Apartment titleAccess="Clientes" />,
              options: { label: "Clientes" }
            },
            {
              name: "users",
              list: UserList,
              show: UserShow,
              create: UserCreate,
              edit: UserEdit,
              canDelete: true,
              icon: <People titleAccess="Usuarios" />,
              options: { label: "Usuarios" }
            },
            {
              name: "sensors/zones",
              edit: SensorZonesEdit,
              options: {
                redirect: {
                    afterEdit: false,
                }
              }
            },
            {
              name: "roles",
              list: RoleList,
              show: RoleShow,
              create: RoleCreate,
              edit: RoleEdit,
              canDelete: true,
              icon: <Lock titleAccess="Roles" />,
              options: { label: "Roles" }
            },
          ]}
        />
      </RefineSnackbarProvider>
    </ColorModeContextProvider>
  );
};

export default App;
