import { AuthProvider } from "@pankod/refine-core";
import { axiosInstance } from "App";
import { dataProviderURL } from "configProvider";
import jwt_decode from "jwt-decode";

const mockUsers = [{ email: "admin", access_token: "123" }, { email: "editor", access_token: "123" }];

export const authProvider: AuthProvider = {
  login: async ({ email, password, remember }) => {
    return await axiosInstance.post(`${dataProviderURL}/auth/login`, {
      email,
      password,
      remember
    })
    .then(function (response) {
      if (!response.data.access_token) {
        throw {
          response: {
            data: {
              message: 'Acceso denegado',
              error: 'Error al iniciar sesión, inténtalo de nuevo más tarde'
            }
          }
        }
      }
      const token = response.data.access_token;
      //localStorage.setItem("auth", JSON.stringify(token));
      localStorage.setItem("auth", token);

      // This sets the authorization headers on Axios instance
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      //axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
      
      return Promise.resolve();
    })
    .catch(function (error) {
      return Promise.reject({
        message: error.response.data.message,
        name: error.response.data.error,
      });
    });
  },
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve("/login");
  },
  checkError: (error) => {
    console.log("checkError", error.response)
    const status = error.response.status;
    if (status === 401) {
      localStorage.removeItem("auth");
      return Promise.reject("/login");
    }
    return Promise.resolve();
  },
  checkAuth: async (params) => {
    if (!localStorage.getItem("auth")) {
      return Promise.reject("/login")
    }

    return await axiosInstance.get(dataProviderURL)
    .then(function (response) {
      return Promise.resolve();
    })
    .catch(function (error) {
      localStorage.removeItem("auth");
      return Promise.reject('/login')
    });

    //return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject("/login");
  },
  getPermissions: (params) => {
    try {
      const token = localStorage.getItem("auth") || "";
      if (token != "") {
        const decodedToken: any = jwt_decode(token);
        if (decodedToken?.roles) {
          return Promise.resolve(decodedToken.roles);
        }
      }
    } catch (error) { }
    localStorage.removeItem("auth");
    return Promise.reject("/login");
  },
  getUserIdentity: (params) => {
    try {
      const token = localStorage.getItem("auth") || "";
      if (token != "") {
        const decodedToken: any = jwt_decode(token);
        if (decodedToken?.username) {
          const user = {
            name: decodedToken.username || "",
            avatar: "",
          };
          return Promise.resolve(user);
        }
      }
    } catch (error) { }
    localStorage.removeItem("auth");
    return Promise.reject("/login");
    //return Promise.resolve({name: "", avatar: ""});
  }
};